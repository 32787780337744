app-keyboard {
    position: relative;
    display: block;

    .keys-row {
        @include setRows(margin-bottom, 1);
        text-align: center;
        @extend %clearfix;

        &.keys-row-OK {
            @include setColumnsWithGutter(margin-right, 3);
        }

        .key {
            @include setColumns(width, 2);
            @include setRows(height line-height, 4);
            @include setGutter(margin-right);
            display: inline-block;
            vertical-align: top;
            @include setProperty(font-size, 90px);
            font-weight: 900;
            position: relative;
            @include setProperty(border-radius, 10px);
            @include not-arris {
                transition: transform $transition-duration $transition-timing-function;
                transform: translateZ(0);
            }

            .key-bg {
                background: rgba($some-gray, 0.2);
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                @include setProperty(border-radius, 10px);
            }

            .key-fg {
                position: relative;
            }

            &:last-child {
                margin-right: 0;
            }

            &.key-active {
                @include not-arris {
                    transform: scale3d(1.25, 1.25, 1);
                }

                .key-bg {
                    background: rgba($some-gray, 0.5) !important;
                }
            }

            /* Keys */
            &.key-MODE {
                @include setColumns(width, 3);

                .key-bg {
                    background: rgba($kuki-turquoise, 0.2);
                }
            }

            &.key-OK {
                .key-bg {
                    background: $kuki-pink;
                }
            }

            &.key-SPACE {
                @include setColumns(width, 12);

                &.key-active {
                    @include not-arris {
                        transform: scale3d(1.05, 1.1, 1);
                    }
                }
            }

            &.key-CAPS {
                .key-bg {
                    background: rgba($kuki-turquoise, 0.2);
                }

                .key-fg {
                    .icon {
                        @include setProperty(top, -7px);
                    }
                }
            }

            &.key-BCKSP {
                @include setColumns(width, 3);

                .key-bg {
                    background: rgba($kuki-turquoise, 0.2);
                }
            }

        }
    }

    &.keyboard-normal {
        .key {
            &.key-SPACE {
                @include setColumnsWithGutter(margin-right, 3);
            }
        }
    }

    &.keyboard-alt {
        .key {
            &.key-SPACE {
                @include setColumnsWithGutter(margin-right, 1);
            }
        }
    }

    &.keyboard-normal, &.keyboard-alt {
        .key {
            &.key-OK {
                @include setColumns(width, 3);
                @include setRows(height line-height, 9);
                @include setRows(margin-bottom, -9);

                &.key-active {
                    @include not-arris {
                        transform: scale3d(1.25, 1.1, 1);
                    }
                }
            }
        }
    }

    &.keyboard-pin {
        .key {
            @include setProperty(width, 284px);
            @include setRows(height line-height, 8);
            @include setGutter(margin-right);

            &.key-active {
                transform: scale3d(1.15, 1.15, 1);
            }

            //&.key-0 {
            //    @include setProperty(width, 610px);
            //    &.key-active {
            //        transform: scale3d(1.05, 1.05, 1);
            //    }
            //}
            &.key-BCKSP {
                @include setProperty(width, 284px);
                @include setRows(height line-height, 8);
                @include setGutter(margin-right);
            }

        }
    }
}

@include theme('senior') {
    app-keyboard:not(.disable-theme) {
        .keys-row {
            .key {
                @include setColumns(width, 3);
                @include setRows(height line-height, 6);
                @include setProperty(font-size, 135px);

                &.key-MODE {
                    @include setColumns(width, 4.5);
                }

                &.key-SPACE {
                    @include setColumns(width, 18);
                }

                &.key-BCKSP {
                    @include setColumns(width, 4.5);

                    .icon {
                        @include setProperty(width, 201px);
                        @include setProperty(height, 90px);
                    }
                }

                &.key-CAPS {
                    .icon {
                        @include setProperty(width, 66px);
                        @include setProperty(height, 68px);
                    }
                }
            }
        }

        &.keyboard-normal {
            .key {
                &.key-SPACE {
                    @include setColumnsWithGutter(margin-right, 4.5);
                }
            }
        }

        &.keyboard-alt {
            .key {
                &.key-SPACE {
                    @include setColumnsWithGutter(margin-right, 1.5);
                }
            }
        }

        &.keyboard-normal, &.keyboard-alt {
            .key {
                &.key-OK {
                    @include setColumns(width, 4.5);
                    @include setRows(height line-height, 13);
                    @include setRows(margin-bottom, -13);
                }
            }
        }

        &.keyboard-pin {
            .key {
                @include setProperty(width, 284px);
                @include setRows(height line-height, 8);

                &.key-BCKSP {
                    @include setProperty(width, 284px);
                    @include setRows(height line-height, 8);
                }
            }
        }
    }
}
