@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-ExtraLight.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-SemiLight.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Franz Sans';
    src: url('~assets/fonts/FranzSans_webfont/FranzSans-Heavy.ttf') format('truetype');
    font-weight: 900;
}
