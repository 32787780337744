app-epg {
    display: block;
    position: relative;
    $height: 7.5;
    @include setRows(padding-top, 6);
    @include setOffset(left);

    &.active {
        .timeline, .selector-date {
            display: block;
        }
    }

    .timeline {
        position: absolute;
        @include setRows(top, 0);
        @include setColumnsWithGutter(left, 5);
        @include setRows(height, 4);

        .timeline-time {
            position: absolute;
            @include setRows(top, 2.2);
            @include setProperty(font-size, 56px);
            font-weight: 900;
            color: $kuki-light;

            &:before {
                content: "";
                @include setProperty(width, 7px);
                @include setProperty(height, 25px);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                background-color: #494263;
                margin: auto;
                @include setProperty(bottom, -100px);
            }
        }
    }

    .channel {
        @include setRows(height, $height);
        @include setRows(margin-bottom, 1);

        .channel-box {
            position: absolute;
            @include setOffset(left, -1);
            @include setColumnsWithOffset(width, 5);
            @include setRows(height, $height);
            @include setRows(line-height, $height);
            @include setProperty(font-size, 42px);
            background-color: $kuki-purple-2;
            @include setColumnsWithOffset(padding-left, 1);
            @include setColumns(padding-right, 1);
            color: $kuki-white;
            font-weight: 900;
            overflow: hidden;
            white-space: nowrap;
            @include not-arris {
                text-transform: uppercase;
                @include setProperty(border-top-right-radius border-bottom-right-radius, 10px);
            }

            .image-link-factory {
                @include setColumnsWithOffset(left, 1);
                background-repeat: no-repeat;
                background-position: left center;
            }

            &.channel-box-active {
                background-color: $kuki-pink;
            }

            .channel-number {
                position: absolute;
                @include setProperty(bottom, 15px);
                @include setProperty(right, 15px);
                line-height: normal;
                @include setProperty(font-size, 42px);
            }
        }

        .channel-entities {
            position: absolute;
            @include setColumnsWithOffset(width, 37);
            @include setColumnsWithGutter(left, 5);
        }

        .channel-entity {
            position: absolute;
            @include setRows(height, $height);
            @include setProperty(left, 15px);
            @include setProperty(right, 15px);
            @include setProperty(padding-left padding-right, 60px);
            text-align: left;
            white-space: nowrap;
            background-color: $kuki-epg;
            font-weight: 900;
            transition: transform $transition-duration $transition-timing-function;
            text-transform: uppercase;
            font-size: 0;
            @include not-arris {
                @include setProperty(border-radius, 10px);
            }

            .tag {
                display: inline-block;
                vertical-align: middle;
                @include setProperty(margin-right, 19px);
            }

            .label {
                display: inline-block;
                vertical-align: middle;
                @include setProperty(font-size, 42px);
                @include setRows(line-height, $height);
            }

            .progress-bar-watched {
                @include setProperty(height, 10px);
                @include setProperty(border-radius, 100px);
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                position: absolute;
                left: 0;
                bottom: 0;
                background: $kuki-pink;
            }

            &.channel-entity-too-small {
                padding-left: 0;
                padding-right: 0;
            }

            &.channel-entity-active {
                background: $kuki-purple;
                z-index: 1;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    @include setProperty(left right top bottom, -10px);
                    @include setProperty(border, 10px, solid $kuki-white);
                    @include not-arris {
                        @include setProperty(border-radius, 15px);
                    }
                }
            }

            &.channel-entity-play-restricted {
                background: $kuki-purple-3;
            }
        }
    }

    .selector-date {
        position: absolute;
        @include setRows(top, 2.2);
        @include setProperty(font-size, 56px);
        font-weight: 900;
        @include setOffset(left, -1);
        @include setColumnsWithOffset(width, 6);
        @include setColumnsWithOffset(padding-left, 1);
        text-align: left;
        color: $kuki-white;
    }

    .epg-future-overlay {
        background-color: rgba(28, 17, 48, 0.3);
        position: absolute;
        right: 0;
        @include setRows(top, 6);
        bottom: 0;
        z-index: 1;
        border-left: solid $kuki-pink;
        @include setProperty(border-width, 5px);
    }
}

@include theme('senior') {
    app-epg {
        .channel {
            .channel-box {
                @include setColumnsWithOffset(width, 6);
                background-color: $kuki-senior-secondary;

                &.channel-box-active {
                    background-color: $kuki-pink;

                    &:after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        @include setProperty(border, 10px, solid $kuki-white);
                        border-left: 0 !important;
                        @include not-arris {
                            @include setProperty(border-radius, 10px);
                        }
                    }
                }
            }

            .channel-entities {
                @include setColumnsWithOffset(width, 36);
                @include setColumnsWithGutter(left, 6);
            }

            .channel-entity {
                background-color: $kuki-senior-secondary;
                &.channel-entity-play-restricted {
                    background: $kuki-button;
                }
            }
        }

        .selector-date {
        }

        &.epg-channel-display-format-number {
            .channel {
                .channel-box {
                    @include setProperty(font-size, 135px);
                }
            }
        }

        &.epg-channel-display-format-text {
            .channel {
                .channel-box {
                    @include setProperty(font-size, 64px);
                }
            }
        }

        &.epg-channel-display-format-number-text {
            .channel {
                .channel-name {
                    @include setProperty(font-size, 64px);
                }

                .channel-number {
                    @include setProperty(font-size, 60px);
                }
            }
        }

        &.epg-channel-display-format-number-logo {
            .channel {
                .channel-number {
                    @include setProperty(font-size, 72px);
                }
            }
        }

        .channel {
            .channel-entity {
                .label {
                    @include setProperty(font-size, 100px);
                }

                .tag {
                    @include setProperty(margin-right, 28.5px);
                }
            }
        }

        .timeline {
            .timeline-time {
                @include setProperty(font-size, 64px);
            }
        }

        .selector-date {
            @include setProperty(padding-top, 4px);
        }
    }
}
