app-search {
    display: block;

    .search-section-title {
        @include h2();
        text-transform: uppercase;
        @include setRows(margin-bottom, 3);
    }

    .filter-panel {
        @include setRows(margin-top, 6);
        @include setColumnsWithGutter(margin-left, 3);
    }

    .search-results {
        @include setRows(margin-top, 4);
        @include setColumnsWithGutter(margin-left, 1, $offsetMult: 1);

        h2 {
            @include setColumns(margin-left, 2);
        }
    }

    .search-last-results {
        @include setRows(margin-top, 4);
        @include setColumnsWithGutter(margin-left, 3);
    }
}

@include theme('senior') {
    app-search {
        .search-section-title {
            @include setProperty(font-size line-height, 100px);
        }
    }
}

