.teletext-digit {
    position: fixed;
    z-index: 2;
    @include setColumns(right, 1);
    @include setRows(top, 7);
    @include arris {
        @include setRows(top, 6);
        @include setColumns(right, 2);
    }
    @include setProperty(font-size, 150px);
    @include setProperty(line-height, 180px);
    color: $kuki-white;
}
