*,
*:after,
*:before {
    @include border-box;
}

.disable-animations {
    *,
    *:after,
    *:before {
        transition: none !important;
    }
}

:focus {
    outline: none;
}

html, body {
    @include setRows(font-size, 1);
    margin: 0;
    padding: 0;
    font-family: 'Franz Sans', sans-serif;
    color: $kuki-white;
    height: 100%;
    overflow: hidden;
    width: 100%;
    // why? http://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
    //-webkit-font-smoothing: antialiased;
    //user-select: none;
}

body {
    background: $backround-main;
    @if $new-theme {
        background: $kuki-dark;
    }

    &.player-open {
        background: transparent !important;
    }
}

//.main {
//    width: 100vw;
//    @include setOffset('padding-left');
//}


.beta-label {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

#logwrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    @include setProperty(padding, 20px);
    @include setProperty(font-size, 56px);
    @include setProperty(line-height, 90px);
    z-index: 9999999;
}

//app-media-player-section-v2 {
//    width: 100vw;
//    height: 100vh;
//    display: block;
//}

.section-arrow-up {
    display: block;
    position: fixed !important;
    @include setRows(top, 2);
    left: 0;
    right: 0;
    margin: auto;
}

.section-arrow-down {
    display: block;
    position: fixed !important;
    @include setRows(bottom, 2);
    left: 0;
    right: 0;
    margin: auto;
}

.right-top-container {
    overflow: hidden;
    position: fixed;
    @include setRows(top, 3);
    @include setColumns(right, 1);
    @include arris {
        @include setColumns(right, 2);
    }

    app-time, app-profile-icon, app-npvr-stats {
        float: right;
        @include setProperty(margin-left, 20px);
    }
}

app-profile-icon {
    .profile-icon {
        @include setRows(height, 3);
        @include setRows(width, 3);
        background-size: contain;
        position: relative;
    }
}

@include theme('senior') {
    background: $kuki-senior-background-main;
    &:before {
        display: none;
    }

    app-profile-icon {
        .profile-icon {
            @include setProperty(width, 100px);
            @include setProperty(height, 100px);
        }
    }

    app-time, app-profile-icon, app-npvr-stats {
        @include setProperty(margin-left, 30px);
    }
}

@include theme('kid') {
    background: $kuki-kid-background-main !important;
}
