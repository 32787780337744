app-progress-button {
    display: block;
    @include setRows(height, 4);
    @include setProperty(padding-left padding-right, 33px);
    @include setProperty(border-radius, 10px);
    background-color: rgba($some-gray, 0.2);
    text-align: center;
    @include setRows(line-height, 4);
    @include setProperty(font-size, 56px);
    font-weight: 800;
    text-transform: uppercase;
    color: $kuki-white;
    .progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        @include setProperty(border-radius, 10px);
        background-color: rgba($some-gray, 0.5);
    }
    .label {
        position: relative;
        z-index: 1;
    }
}
