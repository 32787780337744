.modal-backdrop {
    opacity: 0.76;
    background-color: $kuki-purple;
    @if $new-theme {
        background: url('~assets/overlay/modal.svg');
        background-size: cover;
    }
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1050;
    background: $kuki-modal-bg;
    box-shadow: $kuki-modal-box-shadow;
}

.password-modal, .pin-modal {
    background-image: $grad-background-1;
    box-shadow: none;
    @include setRows(padding-top, 10);

    .modal-title {
        @include h2();
        text-align: center;
        text-transform: uppercase;
    }
}

.confirm-modal {
    text-align: center;
    @include setProperty(width, 1560px);
    @include setProperty(height, 500px);
    @include setRows(padding-top, 3);

    .modal-title {
        @include h2();
        @include setProperty(font-size, 60px);
        @include setProperty(padding-left padding-right, 50px);
    }

    .buttons-panel {
        @include setRows(margin-top, 2);
    }

    &.confirm-modal-teleport {
        @include setProperty(height, 550px);
    }
}

.actions-modal {
    text-align: center;
    @include setProperty(width, 1062px);
    @include setRows(height, 12);
    @include setRows(padding-top, 3);
    overflow: hidden;

    @for $i from 1 through 5 {
        &.actions-modal-#{$i} {
            @include setRows(height, 10 + ($i * 5));
        }
    }

    .modal-title {
        @include h2();
        @include setProperty(font-size, 60px);
    }

    .actions-modal-empty {
        @include setProperty(font-size, 54px);
        @include setRows(margin-top, 1);
    }

    app-buttons-panel {
        display: block;
        @include setRows(margin-top, 0);
        @include setRows(padding-top, 2);
        @include setRows(height, 29, $important: true);

        .button {
            .icon-play-new {
                @include setProperty(width, 40px);
                @include setProperty(height, 40px);
            }

            .icon-pen {
                @include setProperty(width, 52px);
                @include setProperty(height, 52px);
            }

            .icon-trash {
                @include setProperty(width, 53px);
                @include setProperty(height, 56px);
            }
        }
    }
}

@include theme('senior') {
    .modal-title {
        @include setProperty(font-size line-height, 100px, $important: true);
    }

    .actions-modal {
        @include setProperty(width, 1550px);
        @include setRows(height, 12);

        @for $i from 1 through 5 {
            &.actions-modal-#{$i} {
                @include setRows(height, 11 + ($i * 7));
            }
        }

        app-buttons-panel {
            @include setRows(margin-top, 1);
            @include setRows(height, 39, $important: true);
        }
    }

    .confirm-modal {
        @include setProperty(width, 2000px);
        @include setProperty(height, 700px);

        .buttons-panel {
            @include setRows(margin-top, 3);
        }
    }
}

@include theme('kid') {
    .confirm-modal {
        @include setProperty(height, 600px);
    }

    .actions-modal {
        @include setRows(height, 12);

        @for $i from 1 through 5 {
            &.actions-modal-#{$i} {
                @include setRows(height, 12 + ($i * 5));
            }
        }
    }
}
