@mixin border-box {
    //-webkit-box-sizing: border-box;
    //-moz-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin res-720 {
    @if $res == 720 {
        @content;
    }
}


@mixin res-1080 {
    @if $res == 1080 {
        @content;
    }
}

@mixin setColumns($properties, $value, $important:false, $mult: 1) {
    $negativeFlag: if($value >= 0, 1, -1);
    $importantLiteral: if($important, !important, null);
    @each $property in $properties {
        @if $platform == 'TV-ARRIS' {
            #{$property} : get-columns-width($value * $negativeFlag, $grid-column-arris, $grid-gutter-arris) * $negativeFlag * $mult $importantLiteral;
        } @else {
            #{$property} : get-columns-width($value * $negativeFlag, $grid-column-fhd, $grid-gutter-fhd) * $negativeFlag * $mult $importantLiteral;
        }
    }
}

@mixin setColumnsWithOffset($properties, $value, $important: false, $offsetMult: 1, $mult: 1) {
    $negativeFlag: if($value >= 0, 1, -1);
    $importantLiteral: if($important, !important, null);
    @each $property in $properties {
        @if $platform == 'TV-ARRIS' {
            #{$property} : get-columns-width($value * $negativeFlag, $grid-column-arris, $grid-gutter-arris) * $negativeFlag * $mult + ($offset-arris * 1) $importantLiteral;
        } @else {
            #{$property} : get-columns-width($value * $negativeFlag, $grid-column-fhd, $grid-gutter-fhd) * $negativeFlag * $mult + ($offset-fhd * $offsetMult) $importantLiteral;
        }
    }
}

@mixin setColumnsWithGutter($properties, $value, $important: false, $offsetMult: 0, $mult: 1) {
    $importantLiteral: if($important, !important, null);
    $negativeFlag: if($value >= 0, 1, -1);
    @each $property in $properties {
        @if $platform == 'TV-ARRIS' {
            #{$property}: get-columns-width-with-gutter($value * $negativeFlag, $grid-column-arris, $grid-gutter-arris) * $negativeFlag * $mult + ($offset-arris * $offsetMult) $importantLiteral;
        } @else {
            #{$property}: get-columns-width-with-gutter($value * $negativeFlag, $grid-column-fhd, $grid-gutter-fhd) * $negativeFlag * $mult + ($offset-fhd * $offsetMult) $importantLiteral;
        }
    }
}

@mixin setOffset($properties, $mult: 1) {
    @each $property in $properties {
        @if $platform == 'TV-ARRIS' {
            #{$property} : $offset-arris * $mult;
        } @else {
            #{$property} : $offset-fhd * $mult;
        }
    }
}

@mixin setGutter($properties, $mult:1) {
    @each $property in $properties {
        @if $platform == 'TV-ARRIS' {
            #{$property} : $grid-gutter-arris * $mult;
        } @else {
            #{$property} : $grid-gutter-fhd * $mult;
        }
    }
}

@mixin setMaxWidth() {
    @if $platform == 'TV-ARRIS' {
        min-width: $max-width-arris;
    } @else {
        min-width: $max-width-fhd
    }
}

@mixin setRows($properties, $value, $important:false) {
    $importantLiteral: if($important, !important, null);
    @each $property in $properties {
        @if $platform == 'TV-ARRIS' {
            #{$property} : get-rows-height($value, $baseline-arris) $importantLiteral;
        } @else {
            #{$property} : get-rows-height($value, $baseline-fhd) $importantLiteral
        }
    }
}

@mixin setProperty($properties, $uhd, $postfix: null, $important:false) {
    $importantLiteral: if($important, !important, null);
    @each $property in $properties {
        $fhd: if($uhd > 0, round($uhd / 2), ceil($uhd/2));
        $hdready: if($uhd > 0, round($uhd / 3), ceil($uhd/3));
        $sd: if($uhd > 0, round($uhd / 4), ceil($uhd/4));
        //@include uhd {
        //    #{$property} : $uhd $postfix;
        //}
        @if $platform == 'TV-ARRIS' {
            @if $res == 720 {
                #{$property} : $hdready $postfix $importantLiteral;
            }
            @if $res == 1080 {
                #{$property} : $fhd $postfix $importantLiteral;
            }
        } @else {
            #{$property} : $fhd $postfix $importantLiteral;
        }
    }
}

@mixin widthWithOffset($columns) {
    &.width-offset {
        @include setColumnsWithOffset(width, $columns);
    }
}

@mixin setRem($properties, $value, $important: false) {
    $importantLiteral: if($important, !important, null);
    @each $property in $properties {
        #{$property} : get-rem($value) $importantLiteral;
    }
}

@mixin theme($theme) {
    .theme-#{$theme} {
        @content;
    }
}
