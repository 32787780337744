app-profile {
    .profile-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .profile-title {
        @include h2();
        @include setProperty(font-size, 128px);
        @include setRows(padding-top, 11);
        text-transform: uppercase;
        text-align: center;
        @include setRows(margin-bottom, 2);
    }

    .profile-description {
        @include p();
        @include setColumns(width, 22);
        color: rgba($kuki-white, 0.7);
        margin: auto;
        text-align: center;
    }

    app-box-panel {
        display: block;
        position: absolute;
        @include setProperty(top, 960px);
        left: 0;
        right: 0;
    }

    app-select-profile {
    }

    app-create-profile {
    }

    app-select-profile-type {
        .profile-type-description {
            @include p();
            @include setColumns(width, 22);
            color: rgba($kuki-white, 0.7);
            margin: auto;
            text-align: center;
            position: absolute;
            @include setColumns(width, 22);
            @include setRows(top, 52);
            @include setProperty(font-size, 56px);
            left: 0;
            right: 0;
            font-weight: 800;
        }
    }
}

@include theme('senior') {
    app-profile {
        .profile-title {
            @include setProperty(font-size line-height, 100px);
        }

        .profile-description {
            @include setProperty(font-size, 72px);
            @include setProperty(line-height, 80px);
        }

        app-form {
            @include setRows(padding-top, 3);

            app-keyboard {
                @include setRows(margin-top, 4);
            }
        }
    }
}
