.npvr-stats {
    background-color: $kuki-purple-2;
    @include setRows(height, 3);
    @include setProperty(padding-left padding-right, 25px);
    @include setProperty(border-radius, 10px);

    .npvr-stats-value {
        color: $kuki-white;
        font-weight: 900;
        @include setProperty(font-size, 42px);
        @include setRows(line-height, 3);
        display: inline-block;
        vertical-align: middle;
    }

    .icon {
        @include setProperty(margin-right, 10px);
        vertical-align: middle;
    }
}

@include theme('senior') {
    .npvr-stats {
        @include setProperty(height, 100px);

        .npvr-stats-value {
            @include setProperty(line-height, 100px);
            @include setProperty(font-size, 56px);
        }
    }
}
