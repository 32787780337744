.media-detail-new {
    .media-detail-section {
        position: relative;
        //background: $kuki-purple-3;
        @include setRows(padding-top, 6);
        @include setRows(height, 72);
        @include setColumnsWithGutter(padding-left, 3);

        &.media-detail-section-dark {
            background: $some-purple2;
        }

        &.media-detail-section-related {
            @include setRows(padding-top, 35);
            @include setRows(margin-bottom, 5);
        }

        &.media-detail-section-seasons {
            padding: 0;
            @include setRows(margin-bottom, 5);
        }

        .media-detail-section-title {
            @include h2();
            text-transform: uppercase;
            @include setRows(margin-bottom, 2);
        }

        app-tiles-panel-row {
            @include setRows(margin-top, 3);
        }
    }
}

@include theme('senior') {
    .media-detail-new {
        .media-detail-section {
            .media-detail-section-title {
                @include setProperty(font-size line-height, 100px);
            }

            &.media-detail-section-dark {
                background: transparent;
            }
        }
    }
}
