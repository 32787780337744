app-self-test {
    display: block;
    @include setProperty(width, 2160px);
    position: absolute;
    left: 50%;
    top:  50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    .self-test-item {
        text-align: center;
        overflow: hidden;
        @include setProperty(font-size, 90px);
        @include setProperty(line-height, 200px);
        .self-test-name {
            float: left;
            font-weight: 800;
        }
        .self-test-icon {
            float: right;
        }

        &.self-test-error .self-test-icon {
            @include setProperty(font-size, 140px);
            @include setProperty(padding-right, 15px);
        }
    }
}
