app-osd {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    @include not-arris {
        background-image: $grad-osd-preview;
    }
    @include arris {
        background-position: bottom;
        background-repeat: no-repeat;
        @include res-720 {
            background-image: url('~assets/osd/overlay-hdready.png');
        }
        @include res-1080 {
            background-image: url('~assets/osd/overlay-fhd.png');
        }
    }

    .osd-panel {
        position: absolute;
        @include setRows(top, 50);
        @include setColumnsWithGutter(left, 3);
        @include setColumnsWithGutter(right, 3);

        .osd-label {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-transform: uppercase;
            white-space: nowrap;
            @include setProperty(font-size, 150px);
            @include setProperty(line-height, 150px);
            // bigger due to overflow
            @include setProperty(height, 200px);
            font-weight: 900;
            .auto-scroll-box {
                height: 100%;
            }
        }

        .osd-progress-bar-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            @include setRows(top, 6);
            @include setProperty(height, 80px);
        }

        .osd-progress-bar {
            position: absolute;
            @include setRows(top, 1);
            left: 0;
            right: 0;
            @include setProperty(height, 20px);
            @include setProperty(border-radius, 100px);
            background: $kuki-light;

            .osd-progress-bar-live, .osd-progress-bar-watched {
                @include setProperty(height, 20px);
                @include setProperty(border-radius, 100px);
                position: absolute;
                left: 0;
                top: 0;

                &.osd-progress-bar-live {
                    background: $kuki-white;
                }

                &.osd-progress-bar-watched {
                    background: $kuki-pink;
                }
            }

            .osd-progress-bar-cursor {
                border-radius: 50%;
                background-color: $kuki-white;
                position: absolute;
                left: 0;
                @include setProperty(width, 60px);
                @include setProperty(height, 60px);
                @include setProperty(top, -20px);
                @include setProperty(margin-left, -30px);
            }

            .osd-progress-bar-time {
                position: absolute;
                top: 20px;
                right: 0;
                @include setRows(top, 2);
                @include setProperty(font-size, 56px);
                @include setProperty(line-height, 60px);
                font-weight: 800;
                text-align: center;
                @include setProperty(min-width, 220px);
                @include setProperty(height, 60px);
                @include setProperty(margin-right, -110px);

                &.osd-progress-bar-time-total {
                    text-align: right;
                    margin-right: 0;
                }
            }

            .osd-progress-bar-preview {
                .osd-progress-bar-preview-box {
                    position: absolute;
                    @include setColumns(width, 10);
                    @include setRows(height, 17);
                    @include setColumns(margin-left, -10, $mult: 0.5);
                    @include setRows(bottom, 9);
                    @include setProperty(border, 10px, solid $kuki-white);
                    background-color: $kuki-black;
                    background-size: cover;
                    @include not-arris {
                        @include setProperty(border-radius, 15px);
                    }

                    .osd-progress-bar-preview-time {
                        position: absolute;
                        width: 100%;
                        @include setRows(bottom, 1);
                        text-align: center;
                        left: 0;
                        @include setProperty(font-size, 56px);
                        @include setProperty(line-height, 60px);
                        font-weight: 800;
                        z-index: 1;
                    }

                    &:after {
                        content: "";
                        @include not-arris {
                            background-image: $grad-tile;
                        }
                        @include arris {
                            @include res-720 {
                                background-image: url('~assets/osd/overlay-preview-hdready.png');
                            }
                            @include res-1080 {
                                background-image: url('~assets/osd/overlay-preview-fhd.png');
                            }
                        }
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .osd-progress-bar-preview-line {
                content: "";
                position: absolute;
                width: 4px;
                margin-left: -2px;
                @include setRows(height, 8);
                @include setProperty(bottom, 35px);
                background: $osd-line;
                opacity: 0.5;
                left: 50%;
            }
        }
    }

    .osd-context-menu {
        @include setRows(height, 38);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow-x: hidden;
        background: rgba($kuki-purple, 0.81);

        .osd-context-menu-actions {
            position: absolute;
            @include setRows(top, 5);
            left: 0;
            width: 100%;
            text-align: center;
            border-bottom: 1px solid rgba($kuki-white, 0.2);
            @include setRows(padding-bottom, 4);
        }

        .osd-context-menu-detail {
            position: absolute;
            @include setColumns(left, 4);
            @include setRows(top, 19);
            @include setColumns(width, 14);

            app-source-logo {
                @include setRows(margin-bottom, 1);
            }

            .osd-context-menu-detail-description {
                @include p();
                @include setRows(margin-top, 1);
                @include setRows(height, 9);
                overflow: hidden;
                color: rgba($kuki-white, 0.7);
            }
        }

        .osd-context-menu-langs {
            position: absolute;
            @include setColumns(left, 19);
            @include setRows(top, 19);

            .osd-context-menu-lang-row {
                white-space: nowrap;
                @include setRows(margin-bottom, 1);

                .osd-context-menu-lang-row-icon {
                    @include setColumns(width, 3);
                    @include setProperty(margin-right, 45px);
                    display: inline-block;
                    vertical-align: middle;
                    text-align: right;

                    .icon:not(:last-child) {
                        @include setProperty(margin-right, 33px);
                    }
                }

                app-buttons-panel {
                    display: inline-block;
                    vertical-align: middle;
                    @include setColumnsWithGutter(width, 21);
                }
            }
        }
    }

    .osd-paused {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include setProperty(width, 400px);
        @include setProperty(height, 300px);
        @include setProperty(border-radius, 50px);
        background: rgba($kuki-dark, 0.9);
        margin: auto;
        @include setProperty(padding-left padding-right, 50px);

        .icon {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .osd-debug {
        position: absolute;
        background: rgba($some-purple-2, 0.5);
        @include setProperty(border-radius, 5px);
        left: 0;
        right: 0;
        margin: auto;
        font-weight: 900;
        @include setRows(top, 3);
        @include setRows(height, 8);
        @include setProperty(font-size, 90px);
        @include setRows(line-height, 8);
        @include setProperty(padding-left padding-right, 40px);
        @include setColumns(width, 36);
    }
}

@include theme('senior') {
    app-osd {
        .osd-context-menu {
            @include setRows(height, 41);
            .osd-context-menu-detail {
                .osd-context-menu-detail-description {
                    @include setProperty(font-size, 72px);
                    @include setProperty(line-height, 80px);
                }
            }

            .osd-context-menu-langs {
                @include setColumns(left, 22);

                .osd-context-menu-lang-row {
                    //@include setRows(height, 6);
                }
            }
        }
    }
}

@include theme('kid') {
    app-osd {
        .osd-context-menu {
            .osd-context-menu-langs {
                @include setColumns(left, 4);
            }
        }

        app-source-logo {
            position: absolute;
            @include setColumns(left, 4);
            @include setRows(top, 7);
        }
    }
}
