app-labels-panel, .labels-panel {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;

    app-box-labels {
        display: inline-block;
    }

    app-text-labels {
        display: inline-block;

        .label {
            padding: 0;
        }
    }

    .icon {
        vertical-align: middle;
    }

    .label {
        display: inline-block;
        @include setProperty(margin-right, 25px);
        @include setRows(height, 2);
        @include setProperty(min-width, 60px);
        @include setProperty(border-radius, 5px);
        @include setProperty(font-size, 42px);
        @include setProperty(line-height, 60px);
        @include setProperty(padding-left padding-right, 20px);
        font-weight: 900;
        text-align: center;
        color: $kuki-white;
        position: relative;
        vertical-align: middle;

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        &.label-locked {
            background-color: $kuki-black;
            color: $kuki-white;
            text-transform: uppercase;

            .icon {
                position: relative;
                @include not-arris {
                    @include setProperty(width, 42px);
                    @include setProperty(height, 40px);
                }
                @include setProperty(margin-right, 14px);
                @include setProperty(top, 3px);
            }
        }

        &.label-live {
            text-transform: uppercase;
            background-color: $kuki-pink;
        }

        &.label-quality {
            background-color: rgba($some-gray, 0.5);
            color: $kuki-white;
        }

        &.label-age-limit {
            background-color: rgba($some-gray, 0.5);
            color: $kuki-white;
        }

        &.label-tag {
            background-color: rgba($some-gray, 0.5);
            color: $kuki-white;
            @include setRows(width, 2);
        }

        &.label-restriction {
            background-color: $kuki-black;
            color: $kuki-white;
            text-transform: uppercase;
        }

        &.label-sticker {
            text-transform: uppercase;
            background-color: $kuki-lila;
            color: $kuki-white;

            &.label-sticker-1 {
                background-color: $kuki-yellow-2;
            }

            &.label-sticker-2 {
                background-color: $kuki-turquoise;
            }

            &.label-sticker-3 {
                background-color: $kuki-turquoise;
            }
        }

        &.label-device-state {
            background-color: $kuki-pink;
            color: $kuki-white;
            text-transform: uppercase;
            &.label-device-state-off {
                background-color: rgba($some-gray, 0.5);
            }
        }
    }

    .labels-dot {
        @include setProperty(font-size, 42px);
        @include setProperty(line-height, 60px);
        @include setProperty(margin-right, 25px);
        font-weight: 900;
        display: inline-block;
        vertical-align: middle;
    }

    .label-tracks {
        display: inline-block;

        .icon {
            display: inline-block;
            @include setRows(width, 2);
            @include setRows(height, 2);
            @include setProperty(margin-right, 25px);
            background-size: contain;
        }
    }
}

@include theme('senior') {
    app-labels-panel, .labels-panel {
        .label {
            @include setProperty(height, 88px);
            @include setProperty(font-size, 70px);
            @include setProperty(line-height, 88px);

            &.label-tag {
                @include setProperty(width, 88px);
            }
        }

        .labels-dot {
            @include setProperty(font-size, 70px);
            @include setProperty(line-height, 88px);
        }
    }
}
