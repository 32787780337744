// grid variables
$grid-vw-gutter: get-vw($grid-gutter);
$grid-percentage-gutter: percentage(strip-unit($grid-gutter / $max-width));

.container {
    width: $total-width;
    position: relative;
}

.container-fluid {
    width: 100vw;
    @include setOffset('padding-left');
    position: relative;
}

.container-fluid, .row {
    @extend %clearfix;
}

.row {
    @include setMaxWidth();
}
/* new fixed */
[class*='col-'] {
    float: left;
    position: relative;
    min-height: 1px;
    @include setGutter('margin-right');
    &:last-child {
        margin-right: 0;
    }
}

@for $i from 1 through $grid-columns {
    .col-#{$i} {
        @include setColumns(width, $i);
        &.col-flush-left, &.col-flush-right {
            @include setColumnsWithOffset(width, $i);
        }
    }
}

@for $i from 1 through 20 {
    .row-#{$i} {
        @include setRows(height, $i, true);
    }
}

.col-flush-left {
    @include setOffset(margin-left, -1);
}

.col-offset-left {
    @include setOffset(margin-left, 1);
}

.col-full {
    @include setOffset(margin-left, -1);
    @include setColumnsWithOffset(width, 42, true, 2);
}

/* old vw and % */
[class*='colvw-'] {
    float: left;
    position: relative;
    min-height: 1px;
    margin-right: $grid-vw-gutter;
    &:last-child {
        margin-right: 0;
    }
}

@for $i from 1 through $grid-columns {
    .colvw-#{$i} {
        width: get-columns-vw-width($i);
        &.colvw-flush-left, &.colvw-flush-right {
            width: get-columns-vw-width($i) + get-vw($offset);
        }
        &.colvw-flush-left {
            margin-left: -(get-vw($offset));
        }
    }
}

[class*='col-pr-'] {
    float: left;
    position: relative;
    min-height: 1px;
    margin-right: $grid-percentage-gutter;
    &:last-child {
        margin-right: 0;
    }
}

@for $i from 1 through $grid-columns {
    .col-pr-#{$i} {
        $multiplier: $i / $grid-columns;
        width: 100% * $multiplier - $grid-percentage-gutter * (1 - $multiplier);
    }
}
