.dashboard-section, .program-board-section, .serial-section {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include setRows(height, 32);
        @include setColumnsWithOffset(width, 5);
        @include not-arris {
            background: url('~assets/left-panel/left-panel-overlay-fhd.png') left top no-repeat;
        }
        @include arris {
            @include setRows(top, -2);
            @include res-720 {
                background: url('~assets/left-panel/left-panel-overlay-hdready.png') left top no-repeat;
            }
            @include res-1080 {
                background: url('~assets/left-panel/left-panel-overlay-fhd.png') left top no-repeat;
            }
            -webkit-background-size: 100% 100%;
        }
        @if $new-theme {
            background: transparent !important;
        }
        z-index: 1;
    }

    &.program-board-section:before {
        @include setRows(height, 22);
        @include not-arris {
            background: url('~assets/left-panel/left-panel-overlay-small-fhd.png');
        }
        @include arris {
            @include res-720 {
                background: url('~assets/left-panel/left-panel-overlay-small-hdready.png') left top no-repeat;
            }
            @include res-1080 {
                background: url('~assets/left-panel/left-panel-overlay-small-fhd.png') left top no-repeat;
            }
            -webkit-background-size: 100% 100%;
        }
    }
}

@include theme("kid") {
    &.dashboard-section, &.program-board-section, &.serial-section {
        &:before {
            @include setRows(height, 29);
            @include not-arris {
                background-image: url('~assets/left-panel/left-panel-overlay-kid-fhd.png');
            }
            @include arris {
                @include res-720 {
                    background-image: url('~assets/left-panel/left-panel-overlay-kid-hdready.png');
                }
                @include res-1080 {
                    background-image: url('~assets/left-panel/left-panel-overlay-kid-fhd.png');
                }
                -webkit-background-size: 100% 100%;
            }
        }
    }
}
