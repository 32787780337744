.media-progress-bar {
    @include setColumns(width, 15);
    @include setProperty(height, 10px);
    @include setProperty(border-radius, 100px);
    background: $kuki-light;
    position: relative;

    .media-progress-bar-watched, .media-progress-bar-live {
        @include setProperty(height, 10px);
        @include setProperty(border-radius, 100px);
        position: absolute;
        left: 0;
        top: 0;

        &.media-progress-bar-live {
            background: $kuki-white;
        }

        &.media-progress-bar-watched {
            background: $kuki-pink;
        }
    }
}

@include theme('senior') {
    .media-progress-bar {
        @include setProperty(height, 20px);
        .media-progress-bar-watched, .media-progress-bar-live {
            @include setProperty(height, 20px);
        }
    }
}

@include theme('kid') {
    .media-progress-bar {
        @include setProperty(height, 20px);
        .media-progress-bar-watched, .media-progress-bar-live {
            @include setProperty(height, 20px);
        }
    }
}
