/* Animations */
$transition-duration: .3s;
$transition-timing-function: ease;

$vw-precision: 100;

$max-width: 3840px;
$max-height: 2160px;
$total-width: 3822px;
$offset: 9px;
$grid-columns: 42;
$grid-column: 50px;
$grid-gutter: 42px;
$baseline: 30px;

/* New fixed */
//$max-width-uhd: 3840px;
//$offset-uhd: 9px;
//$grid-column-uhd: 50px;
//$grid-gutter-uhd: 42px;
//$baseline-uhd: 30px;

$max-width-fhd: 1920px;
$offset-fhd: 5px;
$grid-column-fhd: 24px;
$grid-gutter-fhd: 22px;
$baseline-fhd: 15px;

//$max-width-hdready: 1280px;
//$offset-hdready: 16px;
//$grid-column-hdready: 18px;
//$grid-gutter-hdready: 12px;
//$baseline-hdready: 10px;

//$max-width-sd: 960px;
//$offset-sd: 2px;
//$grid-column-sd: 13px;
//$grid-gutter-sd: 10px;
//$baseline-sd: 8px;
