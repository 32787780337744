.app-intro {
    .icon-logo {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include setProperty(width, 744px);
        @include setProperty(height, 170px);
        // TODO: remake images to base64
        @include not-arris {
            background-image: url('~assets/icons/png/1080/logo-intro.png');
        }
        @include arris {
            @include res-720 {
                background-image: url('~assets/icons/png/720/logo-intro.png');
            }
            @include res-1080 {
                background-image: url('~assets/icons/png/1080/logo-intro.png');
            }
        }
    }
    .vjs-loading-spinner {
        position: absolute;
        bottom: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}
