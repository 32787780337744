app-box-panel {
    display: block;
    position: absolute;
    @include setProperty(top, 960px);
    left: 0;
    right: 0;
    text-align: center;
    @include not-arris {
        transition: transform $transition-duration $transition-timing-function;
    }

    &.box-panel-overflow {
        @include setColumns(width, 84);
        @include setColumnsWithGutter(left, 5);

        .box {
            float: left;
            display: block;
        }
    }

    .box {
        display: inline-block;
        position: relative;
        @include setColumns(width, 8);
        @include setRows(height, 13);
        background-size: contain;

        &:not(:last-child) {
            @include setProperty(margin-right, 42px);
        }

        @include not-arris {
            transition: transform $transition-duration $transition-timing-function;
            transform: translateZ(0);
        }

        .box-value {
            position: absolute;
            @include setProperty(bottom, 30px);
            @include setProperty(left, 50px);
            @include setProperty(font-size, 42px);
            font-weight: 900;
            text-transform: uppercase;
        }

        &.box-background {
            background: $kuki-purple-2;
        }

        &:before:not(.box-background) {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba($kuki-purple, 0), $kuki-purple);
        }

        &.box-active {
            @include not-arris {
                transform: scale3d(1.16, 1.16, 1);
                z-index: 1;
            }

            &:after {
                content: "";
                position: absolute;
                @include setProperty(left right top bottom, -3px);
                z-index: 1;
                @include setProperty(border, 10px, solid #fff);
                @include setProperty(border-radius, 15px);
            }
        }
    }
}
