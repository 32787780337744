app-auto-scroll-container {
    position: relative;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;

    .auto-scroll-box {
        position: relative;
        left: 0;
        top: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &.auto-scroll-vertical {
        max-height: 100%;
    }

    &.auto-scroll-is-scrolling {
        .auto-scroll-box {
            display: inline-block;
            vertical-align: top
        }
    }
}
