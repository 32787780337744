@font-face {
    font-family: teletext1;
    src: url('~assets/fonts/teletext/teletext1.ttf') format('truetype');
}

@font-face {
    font-family: teletext2;
    src: url('~assets/fonts/teletext/teletext2.ttf') format('truetype');
}

// @font-face {
//     font-family:teletext2wide; src:url('../fonts/teletext2wide.ttf')
// }
@font-face {
    font-family: teletext4;
    src: url('~assets/fonts/teletext/teletext4.ttf') format('truetype');
}

// @font-face {
//     font-family:teletext4wide; src:url('../fonts/teletext4wide.ttf')
// }

.teletext {
    font-family: teletext2, sans-serif !important;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    text-align: center;
    // cerne pozadi pres obraz
    background-color: black;
    letter-spacing: -1px;
    @include setProperty(font-size, 76px);
    @include setProperty(line-height, 72px);
    @include web {
        @include setProperty(font-size, 25px);
        @include setProperty(line-height, 25px);
    }
    @include mobile {
        @include setProperty(font-size, 12px);
        @include setProperty(line-height, 12px);
    }

    * {
        font-family: teletext2, sans-serif !important;
    }

    &.transparent {
        background: transparent;
    }

    // deformace teletextu na 16:9 - zpusobuje artefakty mezi spany. Lepsi by bylo naskalovat font
    // transform: scale(1.5, 1);
    // -webkit-transform: scale(1.5, 1);
    // -moz-transform: scale(1.5, 1);

    .subpage {
        margin-top: 1em;
        @include web {
            margin-top: 3em;
        }
        display: inline-block;
        white-space: pre;
        color: white;
        background: black;
        // display: none;
    }

    &.transparent .subpage {
        background: transparent;

        .b0 {
            background: transparent;
        }

        .b1 {
            background: transparent;
        }

        .b2 {
            background: transparent;
        }

        .b3 {
            background: transparent;
        }

        .b4 {
            background: transparent;
        }

        .b5 {
            background: transparent;
        }

        .b6 {
            background: transparent;
        }

        .b7 {
            background: transparent;
        }
    }

    .subpage.subt .b0, .subpage.subt {
        background: transparent;
        position: relative;
        z-index: 10;
    }

    .subt:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 25%;
        width: 100%;
        z-index: 5;
        background: black;
        opacity: 0.5;
    }

    .f0 {
        color: black;
    }

    .f1 {
        color: red;
    }

    .f2 {
        color: #00ff00;
    }

    .f3 {
        color: yellow;
    }

    .f4 {
        color: blue;
    }

    .f5 {
        color: magenta;
    }

    .f6 {
        color: cyan;
    }

    .f7 {
        color: white;
    }

    .b0 {
        background: black;
    }

    .b1 {
        background: red;
    }

    .b2 {
        background: #00ff00;
    }

    .b3 {
        background: yellow;
    }

    .b4 {
        background: blue;
    }

    .b5 {
        background: magenta;
    }

    .b6 {
        background: cyan;
    }

    .b7 {
        background: white;
    }

    .dh {
        font-family: teletext4, sans-serif !important;
        font-size: 200%;
        line-height: 100%;
    }

    .fl {
        text-decoration: blink
    }
}
