*,
*:after,
*:before {
    transition: none !important;
    box-shadow: none !important;
    animation: none !important;
}

video#videoplane {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;

    &.info-panel-video {
        @include setColumns(width, 26);
        @include setRows(height, 41);
        right: 0;
        left: auto;
        bottom: auto;
    }
}

.arris-fw-ver-4-10 {
    video#videoplane, .bg-video {
        object-fit: fill;
    }
}

@include theme('senior') {
    video#videoplane {
        &.info-panel-video {
            @include setProperty(width, 1716px);
            @include setProperty(height, 900px);
        }
    }
}

@include theme('kid') {
    video#videoplane {
        &.info-panel-video {
            @include setProperty(width, 2024px);
            @include setProperty(height, 1060px);
        }
    }
}

//@for $i from 1 through $grid-columns {
//    .col-arris-#{$i} {
//        @include setColumns(width, $i, true);
//        &.col-flush-left, &.col-flush-right {
//            @include setColumnsWithOffset(width, $i, true);
//        }
//    }
//}
