app-time {
    display: block;
    color: $kuki-white;
    background-color: $kuki-purple-2;
    font-weight: 900;
    @include setRows(height, 3);
    @include setProperty(padding-left padding-right, 25px);
    @include setProperty(font-size, 42px);
    @include setRows(line-height, 3);
    @include setProperty(border-radius, 10px);

    &.time-dev {
        background-color: $some-red;
    }
}

@include theme('senior') {
    app-time {
        @include setProperty(height, 100px);
        @include setProperty(line-height, 100px);
        @include setProperty(font-size, 56px);
    }
}
