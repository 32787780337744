/* The buffering spinner. */
.shaka-spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .shaka-spinner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include setProperty(width, 300px);
        @include setProperty(height, 300px);
        @include web {
            @include setProperty(width, 120px);
            @include setProperty(height, 120px);
        }
        @include mobile {
            @include setProperty(width, 120px);
            @include setProperty(height, 120px);
        }
        /* This is the spinner SVG itself, which contains a circular path element.
     * It sits inside the play button and fills it. */
        .shaka-spinner-svg {
            /* Because of some sizing hacks in the play button (see comments there), this
             * spinner needs to be an overlay child to be properly sized and positioned
             * within the button. */
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            /* Keep it spinning! */
            animation: rotate 2s linear infinite;
            transform-origin: center center;

            /* The SVG should fill its container. */
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
        }

        /* This is the path element, which draws a circle. */
        .shaka-spinner-path {
            /* Fall back for IE 11, where the stroke properties cannot be animated,
             * but the spinner still rotates. */
            stroke: $kuki-pink;
            stroke-dasharray: 20, 200;
            stroke-dashoffset: 0;

            /* Animate the stroke of this circular path. */
            animation: dash 1.5s ease-in-out infinite;

            /* Round the line on the ends. */
            stroke-linecap: round;
        }

        /* Spin the whole SVG. */
        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        /* Pulse the circle's outline forward and backward while it spins. */
        @keyframes dash {
            0% {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
            }
            50% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -35px;
            }
            100% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -124px;
            }
        }
    }
}

.spinner-gif {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
