app-media-detail-intro {
    display: block;
    @include setRows(height, 72);
    position: relative;

    .media-intro-content {
        position: absolute;
        @include setRows(top, 10); // DESIGN : 12
        @include setColumnsWithGutter(left, 3);
        right: 0;
    }

    .media-logo-title {
        @include setRows(height, 25);
        @include setColumns(width, 30); // DESIGN: 24
        display: table-cell;
        vertical-align: bottom;

        .media-title {
            @include h1();
            @include setRows(margin-top, 1);
            @include setRows(line-height, 5);
            text-transform: uppercase;
        }
    }

    app-labels-panel {
        display: block;
        @include setRows(margin-top, 1);
    }

    .media-progress-bar {
        @include setRows(margin-top, 2);
    }

    app-buttons-panel {
        display: block;
        @include setRows(margin-top, 1);
    }

    .media-description {
        @include p();
        @include setRows(margin-top, 2);
        @include setRows(height, 12);
        @include setColumns(width, 15);
        overflow: hidden;
        color: rgba($kuki-white, 0.7);
    }

    app-rating {
        display: block;
        @include setRows(margin-top, 4);
    }
}

@include theme('senior') {
    app-media-detail-intro {
        .media-logo-title {
            .media-title {
                @include setProperty(font-size, 168px);
                @include setProperty(line-height, 200px);
            }
        }

        .media-description {
            @include setProperty(font-size, 72px);
            @include setProperty(line-height, 80px);
        }

        app-buttons-panel {
            @include setRows(margin-top, 1);
        }

        app-rating {
            display: block;
            @include setRows(margin-top, 2);
        }
    }
}

@include theme('kid') {
    app-media-detail-intro {
        .media-intro-content {
            @include setRows(top, 25);
        }
        app-buttons-panel {
            @include setRows(margin-top, 1);
        }

        app-rating {
            display: block;
            @include setRows(margin-top, 5);
        }
    }
}
