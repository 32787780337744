.dashboard-management {
    position: relative;

    .dashboard-management-title {
        @include h2();
        text-transform: uppercase;
    }

    .dashboard-management-panel {
        @include setRows(margin-top, 5);
        @include setColumns(width, 40);
        @include setRows(padding-top padding-bottom, 5);
        @include setColumnsWithGutter(padding-left, 5, $mult: 0.5);
        margin: auto;
    }

    .dashboard-management-grid {
        @include setRows(margin-top, 3);

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .dashboard-management-item-active {
            &:before {
                content: "";
                position: absolute;
                border: solid $kuki-white;
                @include setProperty(border-width, 3px);
                @include setProperty(left right top bottom, -3px);
                @include setProperty(border, 3px, solid $kuki-white);
                @include setProperty(border-radius, 4px);
            }

            @include not-arris {
                transform: scale3d(1.2, 1.2, 1);
                z-index: 1;
            }
        }

        .dashboard-management-item-drag {
            background-color: $kuki-purple;
            border: solid $kuki-white;
            @include setProperty(border-width, 3px);
        }

        .dashboard-management-item-hidden {
            .tiles-row-label, .tiles-row-image, .icon {
                opacity: 0.4;
            }
        }
    }

    app-dashboard-management-item {
        float: left;
        position: relative;
        @include setColumns(width, 7);
        @include setRows(height, 10);
        @include setGutter(margin-right);
        @include setGutter(margin-bottom, 1);
        @include setProperty(font-size, 16px);
        @include setProperty(line-height, 16px);
        font-weight: 900;
        text-transform: uppercase;
        background-color: $kuki-purple-3;
        background-repeat: no-repeat;
        background-position: center center;
        @include setProperty(border-radius, 4px);
        @include not-arris {
            transition: transform $transition-duration $transition-timing-function;
            transform: translateZ(0);
        }

        .tile-legend {
            position: absolute;
            @include setProperty(top, 10px);
            @include setProperty(left, 10px);
        }

        .tiles-row-image {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }

        .tiles-row-label {
            @include setProperty(font-size, 60px);
            @include setRows(line-height, 10);
            text-align: center;
        }

        .tiles-row-channel-number {
            position: absolute;
            @include setProperty(right, 30px);
            @include setProperty(bottom, 30px);
            @include setProperty(font-size, 40px);
            @include setProperty(line-height, 40px);
            font-weight: 600;
            color: rgba($kuki-white, 0.7);
        }

        .icon-hide {
            position: absolute;
            @include setProperty(left, 30px);
            @include setProperty(top, 30px);
        }


        &:nth-child(5n) {
            margin-right: 0;
        }
    }
}

app-dashboard-management {
    .tiles-control {
        position: fixed;
        z-index: 999;
        @include setProperty(width, 80px);
        @include setProperty(height, 83px);
        background-size: 100% 100%;
        transition: transform $transition-duration $transition-timing-function;
        transform: translateZ(0);

        &:hover {
            transform: scale3d(1.5, 1.5, 1);
        }

        &.tiles-control-top {
            @include setProperty(top, 200px);
            background: url('~assets/tv-nav/arrow-up-tv-nav.svg') no-repeat center center;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        &.tiles-control-bottom {
            @include setProperty(top, 2050px);
            background: url('~assets/tv-nav/arrow-down-tv-nav.svg') no-repeat center center;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        &.tiles-control-left {
            @include setRows(top, 40);
            @include setColumnsWithGutter(left, 2);
            background: url('~assets/tv-nav/arrow-left-tv-nav.svg') no-repeat center center;
        }

        &.tiles-control-right {
            @include setRows(top, 40);
            @include setColumnsWithGutter(right, 2);
            background: url('~assets/tv-nav/arrow-right-tv-nav.svg') no-repeat center center;
        }
    }
}
