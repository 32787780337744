app-source-logo {
    @include setColumns(width, 5);
    @include setRows(height, 2); // DESIGN: 2
    display: block;
    position: relative;

    .image-link-factory {
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@include theme('senior') {
    app-source-logo {
        @include setRows(height, 3);
    }
}

