app-tiles-panel {
    position: relative;
    display: block;
    transform: translateZ(0);
    //@include setRows(top, 32);
    //@include setRows(margin-bottom, 5);

    //&.rerender-version {
    //    @include setRows(top, -10);
    //
    //    .tile-cursor-new {
    //        @include setRows(top, 42);
    //    }
    //}

    &.tiles-panel-with-left-panel {
        @include setOffset('left');

        .tile-row-mouse-arrows {
            .mouse-control-left {
                @include setProperty(left, 500px);
            }

            .mouse-control-right {
                @include setProperty(left, 3300px);
            }
        }
    }

    &.tiles-panel-centered {
        margin-left: auto;
        margin-right: auto;
    }

    .tiles-panel-row {
        @include setColumns(width, 45);
        @include setRows(height, 13);
        position: absolute;
    }

    .left-panel-box {
        position: absolute;
        @include setOffset(left, -1);
        @include setRows(height, 13);
        @include setColumnsWithOffset(width, 5);
        @include setRows(margin-bottom, 1);
        background-color: $kuki-purple-2;
        @if $new-theme {
            background-color: $kuki-button;
        }
        @include not-arris {
            text-transform: uppercase;
            @include setProperty(border-top-right-radius border-bottom-right-radius, 10px);
        }

        .left-panel-box-content {
            position: absolute;
            z-index: 3;
            top: 0;
            bottom: 0;
            margin: auto;
            @include setColumnsWithOffset(left, 1);
            @include setColumns(right, 1);
            color: $kuki-white;
            @include setProperty(font-size, 42px);
            @include setRows(line-height, 13);
            font-weight: 900;
            overflow: hidden;
            white-space: nowrap;
            text-transform: uppercase;
        }

        &.left-panel-box-number {
            .left-panel-box-content {
                @include setProperty(font-size, 64px);
            }
        }

        &.left-panel-box-logo {
            img {
                position: absolute;
                z-index: 3;
                margin: auto;
                @include setOffset(left);
                right: 0;
                top: 0;
                bottom: 0
            }
        }

        &.left-panel-box-number-logo {
            img {
                position: absolute;
                z-index: 3;
                margin: auto;
                @include setOffset(left);
                right: 0;
                top: 0;
                bottom: 0
            }

            .left-panel-box-content {
                @include setProperty(font-size, 64px);
                top: auto;
                @include setRows(bottom, 1);
                line-height: normal !important;
            }
        }

        &.left-panel-box-text-logo {
            img {
                position: absolute;
                z-index: 3;
                margin: auto;
                @include setOffset(left);
                right: 0;
                @include setRows(top, -2);
                bottom: 0
            }

            .left-panel-box-content {
                @include setProperty(font-size, 56px);
                top: auto;
                @include setRows(bottom, 1);
                line-height: normal !important;
            }
        }

        &.left-panel-box-number-text {
            .left-panel-box-content-number {
                @include setProperty(font-size, 64px);
                top: auto;
                @include setRows(bottom, 1);
                line-height: normal !important;
            }
        }

        &.left-panel-box-active {
            background: $kuki-pink;
        }
    }

    .tiles-container {
        position: absolute;
        top: 0;
        @include setColumnsWithGutter(left, 5);
        transform: translateZ(0);
        z-index: 0;

        &.no-tiles {
            @include setColumns(width, 40);
            @include setRows(height, 13);
            @include not-arris {
                background: url('~assets/tile/tile-placeholder-tv-fhd.svg') repeat-x left top;
            }
            @include arris {
                @include res-720 {
                    background: url('~assets/tile/tile-placeholder-tv-hdready.png') repeat-x left top;
                }
                @include res-1080 {
                    background: url('~assets/tile/tile-placeholder-tv-fhd.png') repeat-x left top;
                }
            }
        }

        .tile-new {
            background: $kuki-purple;
            @if $new-theme {
                background: $kuki-button;
                //&:before {
                //    content: "";
                //    position: absolute;
                //    top: 0;
                //    left: 0;
                //    right: 0;
                //    bottom: 0;
                //    background-image: linear-gradient(to bottom, rgba($kuki-dark, 0), $kuki-dark);
                //}
            }
            position: absolute;
            display: block;
            @include not-arris {
                @include setProperty(border-radius, 10px);
                transition: transform $transition-duration $transition-timing-function;
            }
            @include setColumns(width, 8);
            @include setRows(height, 13);
            transform: translateZ(0);

            img {
                position: relative;
                @include not-arris {
                    @include setProperty(border-radius, 10px);
                }
                @include arris {
                    @include res-1080 {
                        // TODO: special size images for 4203
                        // on arris 4203 is other size of fhd images
                        width: 100%;
                    }
                }
            }

            &.tile-focused {
                transform: scale3d(1.25, 1.25, 1);
                z-index: 1;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    @include setProperty(left right top bottom, -8px);
                    @include setProperty(border, 10px, solid $kuki-white, $important: true);
                    @include not-arris {
                        @include setProperty(border-radius, 15px);
                    }
                }
            }

            &.tile-focused-left {
                transform-origin: 0 50%;
            }

            &.tile-porn {
                @include not-arris {
                    @include setProperty(border-radius, 10px);
                }
                @include res-720 {
                    background-image: url('~assets/tile/lock/tile-locked-bg-hdready.png');
                }
                @include res-1080 {
                    background-image: url('~assets/tile/lock/tile-locked-bg-fhd.png');
                }
            }

            .tile-start {
                position: absolute;
                @include setRows(bottom, 3);
                @include setColumns(left right, 1);
                @include setProperty(font-size, 42px);
                @include setProperty(line-height, 60px);
                color: rgba($kuki-white, 0.7);
                font-weight: 600;
            }

            .tile-label {
                position: absolute;
                @include setRows(bottom, 1);
                @include setColumns(left right, 1);
                color: $kuki-white;
                text-align: left;
                @include setProperty(font-size, 42px);
                @include setProperty(line-height, 60px);
                font-weight: 900;
                white-space: nowrap;
                overflow: hidden;
                text-transform: uppercase;
            }

            .tile-rating {
                position: absolute;
                @include setColumns(left, 1);
                @include setRows(top, 1);
                background-color: $kuki-white;
                color: $kuki-pink;
                @include setRows(height, 2);
                @include setRows(line-height, 2);
                @include setProperty(font-size, 42px);
                font-weight: 900;
                @include setProperty(padding-right, 13px);
                @include setProperty(padding-left, 60px);
                @include setProperty(border-radius, 5px);

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    @include setProperty(top, 9px);
                    @include setProperty(left, 13px);
                    @include not-arris {
                        background-image: url('~assets/icons/svg/star.svg');
                    }
                    @include arris {
                        @include res-720 {
                            width: 14px;
                            height: 14px;
                            background-image: url('~assets/icons/png/720/star-small.png');
                        }
                        @include res-1080 {
                            width: 21px;
                            height: 20px;
                            background-image: url('~assets/icons/png/1080/star-small.png');
                        }
                    }
                    background-size: contain;
                    @include setProperty(width, 42px);
                    @include setProperty(height, 40px);
                }
            }

            .tile-live {
                position: absolute;
                @include setColumns(left, 1);
                @include setRows(top, 1);
                background-color: $kuki-pink;
                color: $kuki-white;
                @include setProperty(width, 134px);
                @include setRows(height, 2);
                @include setRows(line-height, 2);
                @include setProperty(font-size, 42px);
                font-weight: 900;
                @include setProperty(border-radius, 5px);
                text-transform: uppercase;
                text-align: center;
            }

            .tile-sticker {
                position: absolute;
                @include setColumns(left, 1);
                @include setRows(top, 1);
                text-transform: uppercase;
                background-color: $kuki-lila;
                color: $kuki-white;
                @include setProperty(padding-left padding-right, 13px);
                @include setRows(height, 2);
                @include setRows(line-height, 2);
                @include setProperty(font-size, 42px);
                font-weight: 900;
                @include setProperty(border-radius, 5px);
            }

            .tile-live-progress-bar {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                @include not-arris {
                    @include setProperty(border-radius, 10px);
                }
                @include setProperty(border-left, 8px, solid $kuki-pink);
            }

            .tile-progress-bar {
                position: absolute;
                @include setRows(bottom, 0.5);
                @include setColumns(left, 1);
                @include setColumns(right, 1);
                @include setProperty(height, 10px);
                @include setProperty(border-radius, 100px);
                background: $kuki-light;

                .tile-progress-bar-watched, .tile-progress-bar-live, .tile-progress-bar-time {
                    @include setProperty(height, 10px);
                    @include setProperty(border-radius, 100px);
                    position: absolute;
                    left: 0;
                    top: 0;

                    &.tile-progress-bar-live {
                        background: $kuki-white;
                    }

                    &.tile-progress-bar-watched, &.tile-progress-bar-time {
                        background: $kuki-pink;
                    }
                }
            }

            .tile-tags {
                position: absolute;
                @include setColumns(right, 1);
                @include setRows(top, 1);

                .tile-tag {
                    @include setRows(width height, 2);
                    background-color: rgba($some-gray, 0.5);
                    display: inline-block;
                    @include setProperty(border-radius, 5px);
                    background-repeat: no-repeat;
                    background-position: center center;

                    &:not(:last-child) {
                        @include setProperty(margin-right, 25px);
                    }

                    @include not-arris {
                        &.tile-tag-REC {
                            background-image: url('~assets/icons/svg/record.svg');
                            background-size: 24px 24px;
                        }

                        &.tile-tag-NOTIFY {
                            background-image: url('~assets/icons/svg/notify.svg');
                            background-size: 24px 24px;
                        }

                        &.tile-tag-FAVOURITE {
                            background-image: url('~assets/icons/svg/favourite.svg');
                            background-size: 22px 20px;
                        }
                    }

                    @include arris {
                        @include res-720 {
                            &.tile-tag-REC {
                                background-image: url('~assets/icons/png/720/record.png');
                            }
                            &.tile-tag-NOTIFY {
                                background-image: url('~assets/icons/png/720/notify.png');
                            }

                            &.tile-tag-FAVOURITE {
                                background-image: url('~assets/icons/png/720/favourite.png');
                            }
                        }
                        @include res-1080 {
                            &.tile-tag-REC {
                                background-image: url('~assets/icons/png/1080/record.png');
                            }
                            &.tile-tag-NOTIFY {
                                background-image: url('~assets/icons/png/1080/notify.png');
                            }
                            &.tile-tag-FAVOURITE {
                                background-image: url('~assets/icons/png/1080/favourite.png');
                            }
                        }
                    }
                }
            }

            .tile-icon {
                position: absolute;
                @include setRows(top, 3);
                left: 0;
                right: 0;
                margin: auto;

                &.icon-search, &.icon-search-big, &.icon-remove-circle-big {
                    @include setProperty(width, 150px);
                    @include setProperty(height, 150px);
                }
            }

            .tile-icon-selected {
                position: absolute;
                @include setRows(top, 1);
                @include setColumns(right, 1);

                &.icon-check-circle-fill {
                    @include setProperty(width, 60px);
                    @include setProperty(height, 60px);
                }
            }

            .tile-locked-sticker {
                position: absolute;
                @include setColumns(left, 1);
                @include setRows(top, 1);
                text-transform: uppercase;
                background-color: $kuki-black;
                color: $kuki-white;
                @include setProperty(padding-right, 13px);
                @include setProperty(padding-left, 60px);
                @include setRows(height, 2);
                @include setRows(line-height, 2);
                @include setProperty(font-size, 42px);
                font-weight: 900;
                @include setProperty(border-radius, 5px);

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    @include setProperty(top, 7px);
                    @include setProperty(left, 13px);
                    background-size: contain;
                    background-repeat: no-repeat;
                    @include not-arris {
                        @include setProperty(width, 36px);
                        @include setProperty(height, 45px);
                        background-image: url('~assets/icons/svg/locked.svg');
                    }
                    @include arris {
                        @include res-720 {
                            width: 10px;
                            height: 13px;
                            background-image: url('~assets/icons/png/720/locked.png');
                        }
                        @include res-1080 {
                            width: 18px;
                            height: 22px;
                            background-image: url('~assets/icons/png/1080/locked.png');
                        }
                    }
                }
            }

            .tile-restriction-sticker {
                position: absolute;
                @include setColumns(left, 1);
                @include setRows(top, 1);
                @include setProperty(padding-left padding-right, 13px);
                @include setRows(height, 2);
                @include setRows(line-height, 2);
                @include setProperty(font-size, 42px);
                @include setProperty(border-radius, 5px);
                background-color: $kuki-black;
                color: $kuki-white;
                text-transform: uppercase;
                font-weight: 900;

                &.tile-restriction-sticker-green {
                    background-color: $kuki-green;
                    color: $kuki-white;
                }

                &.tile-restriction-sticker-black {
                    background-color: $kuki-black;
                    color: $kuki-white;
                }
            }
        }

        .tile-person-new {
            background: $kuki-purple;
            position: absolute;
            display: block;
            @include not-arris {
                @include setProperty(border-radius, 10px);
                transition: transform $transition-duration $transition-timing-function;
            }
            @include setColumns(width, 5);
            @include setRows(height, 23);
            transform: translateZ(0);

            img {
                @include not-arris {
                    @include setProperty(border-radius, 10px);
                }
            }

            &.tile-focused {
                transform: scale3d(1.1, 1.1, 1);
                z-index: 1;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    @include setProperty(left right top bottom, -8px);
                    @include setProperty(border, 10px, solid $kuki-white);
                    @include not-arris {
                        @include setProperty(border-radius, 15px);
                    }
                }
            }

            &.tile-focused-left {
                transform-origin: 0 50%;
            }

            .tile-label {
                position: absolute;
                @include setRows(bottom, 3);
                @include setColumns(left right, 1);
                @include setProperty(font-size, 42px);
                @include setProperty(line-height, 60px);
                text-align: left;
                font-weight: 900;
                color: $kuki-white;
                overflow: hidden;
                text-transform: uppercase;
            }
        }

        .tile-profile-active {
            .tile-label {
                color: $kuki-pink;
            }
        }

        .tile-device-teleport {
            .tile-playback-label {
                position: absolute;
                @include setRows(bottom, 3);
                @include setColumns(left right, 1);
                @include setProperty(font-size, 42px);
                @include setProperty(line-height, 60px);
                color: $kuki-white;
                font-weight: 600;
            }
        }

        .tile-channel {
            img {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                @include setProperty(top, -70px);
                width: auto;
            }
        }
    }

    .left-panel-cursor-new {
        background: $kuki-pink;
        color: $kuki-white;
        left: 0;
        top: 0;
        @include setOffset(left, -1);
        position: absolute;
        @include setColumnsWithOffset(width, 5);
        @include setRows(height, 13);
        @include not-arris {
            @include setProperty(border-top-right-radius border-bottom-right-radius, 10px);
        }
        z-index: 2;
    }

    .tile-cursor-new {
        @include setProperty(border, 10px, solid $kuki-white);
        @include not-arris {
            @include setProperty(border-radius, 15px);
        }
        position: absolute;
        @include setColumnsWithGutter(left, 5);
        top: 0;
        z-index: 2;
    }

    .tile-row-mouse-arrows {
        visibility: hidden;
    }
}

app-tiles-panel-grid {
    display: block;
    @include setColumnsWithGutter(margin-left, 1, $offsetMult: 1);

    .tiles-container {
        left: 0 !important;
    }

    .tile-cursor-new {
        left: 0 !important;
    }

    .tile-focused-right {
        transform-origin: 100% 50%;
    }
}

app-tiles-panel-row {
    display: block;
    position: relative;

    &.app-tiles-panel-row-left {
        @include setColumnsWithGutter(margin-left, 1, $offsetMult: 1);
    }

    app-tiles-panel {
        //top: 0;
        margin-bottom: 0;
    }

    .tiles-container {
        left: 0 !important;
    }

    .tile-cursor-new {
        left: 0 !important;
    }

    .tile-row-mouse-arrows {
        .mouse-control-left {
            @include setProperty(left, -200px);
        }

        .mouse-control-right {
            @include setProperty(left, 2800px);
        }
    }
}

app-tiles-panel-people {
    display: block;
    position: relative;

    app-tiles-panel {
        //top: 0;
    }

    .tiles-container {
        left: 0 !important;
    }

    .tile-cursor-new {
        left: 0 !important;
    }

    .tiles-panel-row {
        @include setRows(height, 23);
    }

    .tile-row-mouse-arrows {
        .mouse-control-left {
            @include setProperty(left, -200px);
        }

        .mouse-control-right {
            @include setProperty(left, 2800px);
        }
    }
}

app-program-board, app-media-board {
    app-tiles-panel {
        //@include setRows(top, 22);

        //&.rerender-version {
        //    @include setRows(top, -6);
        //
        //    .tile-cursor-new {
        //        @include setRows(top, 28);
        //    }
        //}
    }
}

app-search {
    //app-tiles-panel {
    //    top: 0;
    //}

    app-tiles-panel-grid {
        margin-left: 0;
    }
}

@include theme('senior') {
    app-tiles-panel {

        .tiles-container {
            &.no-tiles {
                @include setColumns(width, 50);
                @include setRows(height, 15);
                @include not-arris {
                    background: url('~assets/tile/tile-placeholder-tv-fhd-senior.svg') repeat-x left top;
                }
                @include arris {
                    @include res-720 {
                        background: url('~assets/tile/tile-placeholder-tv-hdready-senior.png') repeat-x left top;
                    }
                    @include res-1080 {
                        background: url('~assets/tile/tile-placeholder-tv-fhd-senior.png') repeat-x left top;
                    }
                }
            }
        }

        .left-panel-box {
            background-color: $kuki-purple;
            @include setProperty(border, 4px, solid $kuki-light);
            border-left: 0 !important;
            @include setRows(height, 15);
            @include setColumnsWithOffset(width, 6);


            .left-panel-box-content {
                @include setRows(line-height, 15);
                @include setProperty(font-size, 56px);
            }

            &.left-panel-box-number {
                .left-panel-box-content {
                    @include setProperty(font-size, 120px);
                }
            }

            &.left-panel-box-number-logo, &.left-panel-box-number-text, {
                .left-panel-box-content {
                    @include setProperty(font-size, 72px);
                }
            }
        }

        .left-panel-cursor-new {
            @include setProperty(border, 10px, solid $kuki-white);
            border-left: 0 !important;
            @include setRows(height, 15);
            @include setColumnsWithOffset(width, 6);
        }

        .tile-cursor-new {
            @include setColumnsWithGutter(left, 6);
        }

        .tiles-container {
            @include setColumnsWithGutter(left, 6);

            .tile-new {
                background-color: $kuki-purple;
                @include setColumns(width, 10);
                @include setRows(height, 15);

                &:not(.tile-focused):after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    @include setProperty(left right top bottom, 0);
                    @include setProperty(border, 4px, solid $kuki-light);
                    @include not-arris {
                        @include setProperty(border-radius, 10px);
                    }
                }

                .tile-label {
                    @include setRows(bottom, 2);
                    @include setProperty(font-size, 70px);
                    @include setProperty(line-height, 76px);
                }

                &.tile-focused {
                    &:before {
                        @include setProperty(border, 10px, solid $kuki-white);
                    }
                }

                .tile-live {
                    left: auto;
                    @include setColumns(right, 1);
                    @include setProperty(height, 80px);
                    @include setProperty(line-height, 80px);
                    @include setProperty(font-size, 56px);
                }

                .tile-rating {
                    left: auto;
                    @include setColumns(right, 1);
                    @include setProperty(height, 80px);
                    @include setProperty(line-height, 80px);
                    @include setProperty(font-size, 56px);

                    &:before {
                        @include setProperty(top, 17px);
                    }
                }

                .tile-restriction-sticker {
                    left: auto;
                    @include setColumns(right, 1);
                    @include setProperty(height, 80px);
                    @include setProperty(line-height, 80px);
                    @include setProperty(font-size, 56px);
                }

                .tile-locked-sticker {
                    @include setProperty(height, 80px);
                    @include setProperty(line-height, 80px);
                    @include setProperty(font-size, 56px);

                    &:before {
                        @include setProperty(top, 17px);
                    }
                }

                .tile-sticker {
                    left: auto;
                    @include setColumns(right, 1);
                    @include setProperty(height, 80px);
                    @include setProperty(line-height, 80px);
                    @include setProperty(font-size, 56px);
                }

                .tile-start {
                    bottom: auto;
                    right: auto;
                    @include setRows(top, 1);
                    @include setProperty(height, 80px);
                    @include setProperty(line-height, 80px);
                    @include setProperty(font-size, 56px);
                    background-color: $kuki-white;
                    color: $kuki-purple-2;
                    font-weight: 900;
                    text-align: center;
                    @include setProperty(width, 170px);
                    @include setProperty(border-radius, 5px);
                }

                .tile-progress-bar {
                    @include setRows(bottom, 1);
                    @include setProperty(height, 20px);

                    .tile-progress-bar-watched, .tile-progress-bar-live, .tile-progress-bar-time {
                        @include setProperty(height, 20px);
                    }
                }

                .tile-tags {
                    top: auto;
                    @include setColumns(left, 1);
                    @include setRows(bottom, 4);

                    .tile-tag {
                        @include setProperty(width height, 80px);
                    }
                }
            }

            .tile-person-new {
                @include setColumns(width, 6);
                @include setRows(height, 25);

                .tile-label {
                    @include setProperty(font-size, 70px);
                    @include setProperty(line-height, 76px);
                }

                &:not(.tile-focused):after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    @include setProperty(left right top bottom, 0);
                    @include setProperty(border, 4px, solid $kuki-light);
                    @include not-arris {
                        @include setProperty(border-radius, 10px);
                    }
                }
            }

            .tile-device-teleport {
                .tile-playback-label {
                    @include setRows(bottom, 4.5);
                }
            }
        }
    }

    app-tiles-panel-people {
        .tiles-panel-row {
            @include setRows(height, 25);
        }
    }
}

@include theme('kid') {
    app-tiles-panel {
        .tiles-container {
            &.no-tiles {
                @include setColumns(width, 45);
                @include setRows(height, 14);
                @include not-arris {
                    background: url('~assets/tile/tile-placeholder-tv-fhd-kid.svg') repeat-x left top;
                }
                @include arris {
                    @include res-720 {
                        background: url('~assets/tile/tile-placeholder-tv-hdready-kid.png') repeat-x left top;
                    }
                    @include res-1080 {
                        background: url('~assets/tile/tile-placeholder-tv-fhd-kid.png') repeat-x left top;
                    }
                }
            }
        }

        .left-panel-box {
            background-color: $kuki-purple-4;
            @include not-arris {
                text-transform: uppercase;
                @include setProperty(border-top-right-radius border-bottom-right-radius, 20px);
            }
            @include setRows(height, 14);

            .left-panel-box-content {
                @include setRows(line-height, 14);
            }
        }

        .tiles-container {
            .tile-new {
                @include setColumns(width, 9);
                @include setRows(height, 14);
                @include not-arris {
                    @include setProperty(border-radius, 20px);
                }

                &.tile-focused {
                    &:before {
                        @include not-arris {
                            @include setProperty(border-radius, 20px);
                        }
                        @include setProperty(border-width, 5px);
                        @include setProperty(left right top bottom, -3px);
                    }
                }

                img {
                    @include not-arris {
                        @include setProperty(border-radius, 20px);
                    }
                }

                .tile-label {
                    @include setProperty(font-size, 56px);
                    @include setProperty(line-height, 60px);
                }
            }

            .tile-person-new {
                @include not-arris {
                    @include setProperty(border-radius, 10px);
                }

                &.tile-focused {
                    &:before {
                        @include not-arris {
                            @include setProperty(border-radius, 20px);
                        }
                    }
                }

                img {
                    @include not-arris {
                        @include setProperty(border-radius, 20px);
                    }
                }
            }
        }

        .tile-cursor-new {
            @include not-arris {
                @include setProperty(border-radius, 20px);
            }
            @include setProperty(border-width, 5px);
        }

        .left-panel-cursor-new {
            @include setRows(height, 14);
            @include not-arris {
                @include setProperty(border-top-right-radius border-bottom-right-radius, 20px);
            }
        }
    }

    app-tiles-panel-grid {
        display: block;
        @include setColumnsWithGutter(margin-left, 3);
    }
}

@include lang('sk_SK') {
    app-tiles-panel {
        .tile-new {
            .tile-live {
                @include setProperty(width, 180px);
            }
        }
    }
}

@include lang('en_US') {
    app-tiles-panel {
        .tile-new {
            .tile-restriction-sticker {
                @include setProperty(font-size, 36px, $important: true);
            }
        }
    }
}

@include langAndTheme('sk_SK', 'senior') {
    app-tiles-panel {
        .tile-new {
            .tile-live {
                @include setProperty(width, 230px);
            }
        }
    }
}
