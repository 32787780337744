app-form {
    display: block;
    margin: auto;
    position: relative;
    z-index: 1;
    @include setRows(padding-top, 7);

    .input-label {
        @include setProperty(font-size, 60px);
        @include setProperty(line-height, 90px);
        font-weight: 900;
        color: $kuki-light;
    }

    .input-group {
        @include setColumns(width, 18);
        margin: auto;
        position: relative;

        .input {
            color: $kuki-white;
            background: transparent;
            white-space: pre;
            border: 0;
            font-family: 'Franz Sans';
            width: 100%;
            @include setProperty(padding-right, 120px);
            @include setRows(font-size, 3);
            @include setRows(height line-height, 5);
            font-weight: 900;
            @include setProperty(border-bottom, 2px, solid $kuki-light);
            @include res-720 {
                border-bottom: 2px solid $kuki-light;
            }
            @include setRows(padding-bottom, 1);
        }

        .placeholder {
            color: $kuki-light;
            position: absolute;
            top: 0;
            @include setProperty(top, 30px);
            left: 0;
            @include setProperty(font-size, 90px);
            @include setProperty(line-height, 90px);
            font-weight: 900;
            //z-index: -1;
        }

        .icon {
            position: absolute;
            right: 0;
            @include setProperty(top, 30px);
        }

        &.input-group-error {
            .input {
                border-color: $some-red;
            }

            .input-error-icon {
                position: absolute;
                right: 0;
                top: 0;
            }

            .input-error-message {
                position: absolute;
                @include setProperty(bottom, -75px);
                left: 0;
                @include setProperty(line-height, 60px);
                @include setProperty(font-size, 45px);
                font-weight: 800;
                color: $some-red;
            }
        }
    }

    &.form-active {
        .input:after {
            content: '';
            display: inline-block;
            width: 2px;
            position: relative;
            background: $kuki-white;
            @include setProperty(height, 80px);
            @include setProperty(top, 12px);
            @include setProperty(left, 0px);
            animation: blink-animation 1s steps(2, start) infinite;
            @keyframes blink-animation {
                to {
                    visibility: hidden;
                }
            }
            @-webkit-keyframes blink-animation {
                to {
                    visibility: hidden;
                }
            }
        }
    }

    app-keyboard {
        margin: auto;
        display: block;
        @include setRows(margin-top, 5);
    }

    .hints {
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 13);

        h3 {
            color: $kuki-light;
        }

        strong {
            display: block;
            @include setProperty(font-size, 90px);
            @include setProperty(line-height, 90px);
            font-weight: 900;
        }
    }
}

@include theme('senior') {
    app-form {
        .input-label {

        }

        .input-group {
            .input {
                @include setRows(font-size line-height height, 4.5);
            }
        }

        &.form-active {
            .input:after {
                @include setProperty(height, 120px);
            }
        }
    }
}
