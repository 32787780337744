app-info-panel {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    @include setRows(height, 41);
    @include setColumnsWithGutter(width, 37);
    background-position: top right;
    background-repeat: no-repeat;

    //
    //@if $new-theme {
    //    &.info-panel-visible {
    //        &:before {
    //            content: "";
    //            position: absolute;
    //            @include setColumns(width, 26);
    //            top: 0;
    //            right: 0;
    //            bottom: 0;
    //            background: url('~assets/overlay/1080/backdrop.png');
    //        }
    //    }
    //}

    &.info-panel-left {
        @include setColumnsWithOffset(width, 42);

        .media-description {
            @include setColumns(width, 19);
        }
    }

    app-source-logo {
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 4);
        @include setColumns(width, 3);
    }

    .info-panel-title {
        @include h1();
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 7);
        text-transform: uppercase;
        white-space: nowrap;
        @include not-arris {
            text-overflow: ellipsis;
        }
    }

    app-labels-panel, .labels-panel {
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 14);
        @include setColumns(width, 14);
    }

    app-rating {
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 14);
    }

    .media-description {
        @include p();
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 18);
        @include setColumns(width, 14);
        @include setRows(height, 10);
        opacity: 0.7;
        overflow: hidden;
        z-index: 1;
    }

    .info-panel-teleport-label {
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 17);
        @include setColumns(width, 14);
        @include setProperty(font-size, 56px);
        font-weight: 800;
        color: rgba($kuki-white, 0.7);
    }

    .media-progress-bar {
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 20);
    }

    app-media-player-v2 {
        @include setColumns(width, 26);
        @include setRows(height, 41);
        @include setProperty(width, 2348px);
        @include setProperty(height, 1230px);
        position: absolute;
        right: 0;
        overflow: hidden;
        background-position: center;
        background-size: cover;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            @include setProperty(left bottom, -10px);
            background-size: cover;
            @include res-1080 {
                background-image: url('~assets/overlay/backdrop.1920.png');
            }
            @include res-720 {
                background-image: url('~assets/overlay/backdrop.1280.png');
            }
        }

        .shaka-player {
            position: absolute;
            height: auto;
        }

        .shaka-spinner-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@include theme('senior') {
    app-info-panel {
        @include res-1080 {
            background-size: 858px 450px;
        }

        @include res-720 {
            background-size: 572px 300px;
        }

        app-source-logo {
            @include setRows(top, 6);
        }

        .info-panel-title {
            @include setRows(top, 10);
            @include setProperty(font-size, 168px);
            @include setProperty(line-height, 200px);
        }

        app-rating {
            @include setRows(top, 17);
        }

        app-labels-panel {
            @include setRows(top, 17);
        }

        app-media-player-v2 {
            @include setProperty(width, 1716px);
            @include setProperty(height, 900px);

            &:after {
                @include res-1080 {
                    background-image: url('~assets/overlay/backdrop-senior.1920.png');
                    @include atv {
                        background-image: url('~assets/overlay/backdrop-senior.1920.atv.png');
                    }
                }
                @include res-720 {
                    background-image: url('~assets/overlay/backdrop-senior.1280.png');
                }
            }
        }
    }
}

@include theme('kid') {
    app-info-panel:not(.info-panel-small) {
        app-source-logo {
            @include setRows(top, 10);
        }

        .info-panel-title {
            @include setRows(top, 12);
        }

        app-rating {
            @include setRows(top, 18);
        }
    }

    app-info-panel {
        @include res-1080 {
            background-size: 1012px 530px;
        }

        @include res-720 {
            background-size: 675px 353px;
        }

        app-media-player-v2 {
            @include setProperty(width, 2024px);
            @include setProperty(height, 1060px);

            &:after {
                @include res-1080 {
                    background-image: url('~assets/overlay/backdrop-kid.1920.png');
                    @include atv {
                        background-image: url('~assets/overlay/backdrop-kid.1920.atv.png');
                    }
                }
                @include res-720 {
                    background-image: url('~assets/overlay/backdrop-kid.1280.png');
                }
            }
        }
    }
}
