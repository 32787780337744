.wizard-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .wizard-title {
        @include h2();
        @include setProperty(font-size, 128px);
        @include setRows(padding-top, 11);
        text-transform: uppercase;
        text-align: center;
        @include setRows(margin-bottom, 2);
    }

    .wizard-description {
        @include p();
        @include setProperty(font-size, 64px);
        @include setColumns(width, 22);
        color: rgba($kuki-white, 0.7);
        margin: auto;
        text-align: center;
    }

    app-pair-device {
        &:before, &:after {
            content: "";
            position: absolute;
            display: block;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: left top;
        }

        &:before {
            background-image: url('~assets/wizard/connection-left-pink.svg');
            @include arris {
                background-image: url('~assets/wizard/png/connection-left-pink.png');
            }
            @include setRows(height, 15);
            @include setColumnsWithGutter(width, 6);
            @include setColumnsWithGutter(left, 10);
            @include setRows(top, 30);
        }

        &:after {
            background-image: url('~assets/wizard/connection-right.svg');
            @include arris {
                background-image: url('~assets/wizard/png/connection-right.png');
            }
            @include setRows(height, 5);
            @include setColumns(width, 4);
            @include setColumnsWithGutter(left, 25);
            @include setRows(top, 32);
        }

        .pair-url {
            color: $kuki-pink;
        }

        .kuki-cloud {
            @include setColumns(width, 7);
            @include setRows(height, 11);
            margin: auto;
            background: url('~assets/wizard/kuki-cloud.svg') left top no-repeat;
            @include arris {
                @include res-720 {
                    background: url('~assets/wizard/png/kuki-cloud.png') left top no-repeat;
                }
                @include res-1080 {
                    background: url('~assets/wizard/png/kuki-cloud-1080.png') left top no-repeat;
                }
            }
            background-size: 100% 100%;
            position: relative;

            .icon {
                @include setProperty(width, 296px);
                @include setProperty(height, 101px);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: block;
                @include arris {
                    @include res-720 {
                        width: 98px;
                        height: 23px;
                    }
                    @include res-1080 {
                        width: 148px;
                        height: 50px;
                    }
                }
            }

            @include setRows(margin-top, 5);
        }

        .device-side {
            position: absolute;
            @include setRows(top, 45);
            @include setColumns(left, 7);

            .device-pc {
                background: url('~assets/wizard/pc.svg') left top no-repeat;
                @include arris {
                    background: url('~assets/wizard/png/pc.png') left top no-repeat;
                }
                background-size: 100%;
                @include setProperty(width, 1050px);
                @include setRows(height, 17);
                position: relative;
                float: left;

                .pair-code {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    @include setProperty(width, 740px);
                    @include setRows(top, 5);

                    .pair-code-letter {
                        display: inline-block;
                        text-align: center;
                        @include setProperty(font-size, 84px);
                        @include setProperty(line-height, 150px);
                        font-weight: 900;
                        background: rgba($kuki-white, 0.1);
                        @include setProperty(margin-right, 10px);
                        @include setProperty(width, 114px);
                        @include setRows(height, 5);

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .pair-state {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    @include setProperty(width, 490px);
                    @include setProperty(top, 140px);
                    text-align: center;
                }
            }

            .device-phone {
                position: absolute;
                bottom: 0;
                @include setProperty(right, -180px);
                @include setProperty(width, 226px);
                background: url('~assets/wizard/phone.svg') left top no-repeat;
                @include arris {
                    background: url('~assets/wizard/png/phone.png') left top no-repeat;
                }
                background-size: 100% 100%;
                @include setRows(height, 8);

            }
        }

        .tv-side {
            background: url('~assets/wizard/tv.svg') left top no-repeat;
            @include arris {
                background: url('~assets/wizard/png/tv.png') left top no-repeat;
            }
            background-size: 100% 100%;
            position: absolute;
            @include setProperty(width, 1238px);
            @include setRows(height, 26);
            @include setRows(top, 40);
            @include setColumns(left, 22);

            .pair-state {
                position: absolute;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
                @include setProperty(font-size, 90px);
                @include setProperty(line-height, 90px);
                font-weight: 900;
                @include setRows(top, 9);
                @include setProperty(padding-left padding-right, 60px);
            }
        }

        &.paired {
            &:after {
                background-image: url('~assets/wizard/connection-right-pink.svg');
                @include arris {
                    background-image: url('~assets/wizard/png/connection-right-pink.png');
                }
            }

            .tv-side {
                .pair-state {
                    @include setProperty(top, 256px);
                }
            }
        }
    }

    app-select-lang {
        app-box-panel {
            display: block;
            position: absolute;
            @include setProperty(top, 960px);
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}

.bg-video {
    @include setProperty(width, 3840px);
    @include setProperty(height, 2160px);
}
