app-settings {
    display: block;
    position: relative;
    background: #220B36;
    @include setRows(height, 72);

    .settings-title {
        @include h1();
        position: absolute;
        @include setColumnsWithGutter(left, 3);
        @include setRows(top, 7);
        text-transform: uppercase;
    }

    .settings-bg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        opacity: 1;
        transition: opacity $transition-duration $transition-timing-function;
        left: 0;
        @include setProperty(top, 413px);
        @include setProperty(width, 1993px);
        @include setProperty(height, 1747px);
    }

    .settings-wrapper {
        position: absolute;
        min-width: 100%;
        height: 100%;
        top: 0;
        transition: transform $transition-duration / 2 $transition-timing-function;
    }

    .settings-panel-wrapper {
        position: absolute;
        @include setRows(height, 41);
        @include setRows(top, 16);
        @include setColumns(right, 4);
        @include setColumns(width, 16);
        z-index: 1;

        &.settings-panel-wrapper-big {
            @include setColumns(width, 20);

            .settings-panel {
                .settings-panel-items {
                    .settings-panel-item {
                        .settings-key {
                            @include setColumns(width, 15);
                        }
                    }
                }
            }

            &.settings-panel-wrapper-inactive {
                @include setColumns(width, 14);

                .settings-panel {
                    .settings-panel-items {
                        .settings-panel-item {
                            .settings-key {
                                @include setColumns(width, 12);
                            }
                        }
                    }
                }
            }
        }
    }

    .settings-panel-wrapper-keyboard {
        height: 100%;

        .settings-panel {
            max-height: none !important;

            app-keyboard {
                width: 100%;
                @include setProperty(margin-top, 120px);
            }
        }
    }

    .settings-content {
        position: absolute;
        top: 0;
        height: 100%;
        @include setColumnsWithGutter(left, 21, $offsetMult: 1);
        @include setColumnsWithOffset(width, 42);
    }

    .settings-subcontent {
        position: absolute;
        top: 0;
        height: 100%;
        @include setColumnsWithGutter(left, 42, $offsetMult: 1);
        @include setColumnsWithOffset(width, 42);
    }

    .settings-panel {
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        @include setColumns(padding-left padding-right, 1);
        @include setRows(max-height, 41);
        @include setRows(top, -1);
        @include setRows(padding-top, 1);

        .settings-panel-title {
            @include h2();
        }

        .settings-panel-items {
            transition: transform $transition-duration $transition-timing-function;

            .settings-panel-item {
                @include setRows(height, 4);
                @include setProperty(padding-left padding-right, 50px);
                @include setProperty(border-radius, 10px);
                @include setRows(margin-bottom, 1);
                background: rgba($some-gray, 0.2);
                overflow: hidden;
                transition: opacity $transition-duration $transition-timing-function;
                position: relative;

                .settings-icon {
                    float: left;
                    display: inline-block;
                    @include setRows(line-height, 4);
                    @include setProperty(margin-right, 30px);
                    .icon {
                        vertical-align: middle;
                    }
                }

                .settings-key {
                    float: left;
                    @include setProperty(font-size, 56px);
                    @include setRows(line-height, 4);
                    font-weight: 800;
                    @include setColumns(width, 10);
                    white-space: nowrap;
                    overflow: hidden;

                    &.settings-disabled {
                        opacity: 0.4;
                    }
                }

                .settings-text {
                    float: right;
                    @include setProperty(font-size, 42px);
                    @include setRows(line-height, 4);
                    @include setRows(height, 4);
                    color: rgba($kuki-white, 0.7);
                    position: relative;

                    .icon-done-circle-small {
                        @include setProperty(width, 60px);
                        @include setProperty(height, 60px);
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                    }
                }

                .badge-beta {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    @include setProperty(right, 200px);
                    background: $kuki-pink;
                    @include setProperty(padding-left padding-right, 20px);
                    @include setProperty(height, 60px);
                    @include setProperty(font-size, 42px);
                    @include setProperty(line-height, 60px);
                    @include setProperty(border-radius, 10px);
                    font-weight: 800;
                }

                &.settings-panel-item-active {
                    background-color: rgba($some-gray, 0.5);
                    box-shadow: $kuki-settings-panel-item-box-shadow;
                    @include not-arris {
                        transform: scale3d(1.05, 1.05, 1);
                    }
                }

                &.settings-panel-item-disabled {
                    color: rgba($some-gray, 0.3);
                }

                &.settings-panel-fade-out {
                    opacity: 0.2;
                }
            }
        }
    }

    .settings-panel-wrapper-inactive {
        .settings-panel-items {
            .settings-panel-item {
                opacity: 0.3;

                &.settings-panel-item-active {
                    opacity: 1;

                    &:after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        content: "";
                        border: solid 4px $kuki-white;
                    }
                }
            }
        }
    }

    .settings-panel-wrapper-uppercase {
        .settings-panel .settings-key {
            text-transform: uppercase;
        }
    }

    .settings-empty {
        @include h3();
        position: absolute;
        left: 0;
        right: 0;
        @include setColumnsWithGutter(width, 15);
        margin: auto;
        @include setRows(top, 30);
        @include setProperty(line-heigth, 100%);

        .notification-content {
            .notification-title {
                text-transform: none;
                @include setProperty(line-height, 140px);
            }
        }
    }

    .settings-panel-hint {
        position: absolute;
        @include setRows(bottom, -10);
        @include setProperty(left, 50px);
        right: 0;

        .settings-panel-hint-title {
            @include h3();
            text-transform: uppercase;
        }

        .settings-panel-hint-content {
            @include p();
            @include setRows(height, 6);
        }
    }

    app-settings-device-management {
        .settings-panel-devices {
            .settings-panel-wrapper {
                @include setRows(height, 45);

                .settings-panel {
                    @include setRows(max-height, 45);
                }
            }

            .device {
                position: relative;
                @include setProperty(top, 20px);

                .device-content {
                    position: absolute;
                    @include setProperty(left, 210px);
                    @include setColumns(width, 11);
                    top: 0;
                    @include setProperty(font-size, 42px);
                    @include setProperty(line-height, 50px);
                    font-weight: 600;

                    .device-title {
                        @include h3();
                    }

                    .device-sn {
                        overflow: hidden;

                        .device-sn-label {
                            float: left;
                        }

                        .device-sn-value {
                            float: left;
                            word-wrap: break-word;
                            @include setColumns(width, 9);
                        }
                    }
                }

                .icon {
                    position: absolute;
                    right: 0;
                    @include setProperty(top, 60px);
                    @include setProperty(width, 60px);
                    @include setProperty(height, 60px);
                }
            }

            .settings-panel-item {
                @include setRows(height, 8, $important: true)
            }
        }

        .settings-device-licence-info {
            position: absolute;
            @include setProperty(left, 210px);
            @include setColumns(width, 11);
            @include setProperty(top, 1800px);
            color: rgba($kuki-white, 0.7);
            @include setProperty(font-size, 42px);
            @include setProperty(line-height, 60px);
            font-weight: 600;

            &.settings-device-licence-info-2 {
                @include setColumnsWithGutter(left, 23, $offsetMult: 1);
                @include setProperty(top, 1950px);
            }
        }

        .settings-panel-wrapper-keyboard {
            @include setColumns(width, 22);
            @include setProperty(right, 140px);
        }

        .settings-box-active-profile {
            position: absolute;
            @include setRows(top, 16);
            @include setColumnsWithGutter(right, 4);
            @include setColumns(width, 15);
            overflow: hidden;
            @include setProperty(font-size, 54px);
            background: rgba($some-gray, 0.2);
            @include setProperty(border-radius, 10px);
            @include setProperty(padding-top padding-bottom padding-left padding-right, 50px);

            .active-profile-title {
                float: left;
                @include setRows(line-height, 3);
                @include setProperty(margin-right, 30px);
                font-weight: 900;
                text-transform: uppercase;
            }

            .active-profile-label {
                float: left;
                @include setRows(line-height, 3);
            }

            .profile-icon {
                float: left;
                @include setProperty(margin-right, 30px);
            }
        }
    }

    .settings-audio-sub {
        .settings-panel {
            bottom: auto;
            top: 0;
        }
        .audio-title, .subtitle-title {
            @include h3();
            position: absolute;
            @include setColumns(width, 16);

            &.audio-title {
                @include setRows(top, 17);
                @include setColumns(right, 1);
            }

            &.subtitle-title {
                @include setRows(top, 17);
                @include setColumnsWithGutter(left, 43, $offsetMult: 1);
            }
        }

        .settings-panel-audio {
            .settings-panel-wrapper {
                @include setRows(top, 20);
                @include setRows(height, 18);
                @include setColumnsWithGutter(right, 1);
            }
        }

        .settings-panel-subtitle {
            .settings-panel-wrapper {
                @include setRows(height, 18);
                @include setRows(top, 20);
                @include setColumns(left, 43);
            }
        }

        .settings-panel-audio-channel {
            .settings-panel-wrapper {
                @include setRows(height, 18);
                @include setRows(top, 37);
                @include setColumnsWithGutter(right, 1);
            }
        }

        .settings-panel-subtitle-channel {
            .settings-panel-wrapper {
                @include setRows(height, 18);
                @include setRows(top, 37);
                @include setColumns(left, 43);
            }
        }

        .settings-content {
            @include setColumnsWithGutter(left, 42);
            .settings-content-title {
                @include h3();
                position: absolute;
                @include setColumns(width, 16);
                @include setRows(top, 17);
                @include setColumns(right, 3);
            }
            .settings-panel-wrapper {
                @include setRows(top, 20);
                @include setColumnsWithGutter(right, 3);
            }
        }
    }

    app-settings-device-info {
        display: block;
        position: absolute;
        @include setProperty(right, 360px);
        @include setColumns(width, 15);
        @include setProperty(top, 650px);
        background: rgba($some-gray, 0.2);
        @include setProperty(border-radius, 10px);
        @include setProperty(padding-top padding-bottom padding-left padding-right, 50px);
        overflow: hidden;
        @include setProperty(font-size, 40px);
        word-wrap: break-word;

        .settings-device-info-figure {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            @include setProperty(width, 227px);
            @include setProperty(height, 227px);
            @include setProperty(margin-right, 50px);
            float: left;
            position: relative;
        }

        .settings-device-info-content {
            float: left;
            font-weight: 600;
            @include setProperty(width, 890px);
            @include setProperty(line-height, 60px);
            @include setProperty(font-size, 42px);
            word-wrap: break-word;

            .device-title {
                @include h2();
                text-transform: uppercase;
                @include setProperty(font-size, 56px);
                @include setProperty(line-height, 60px);
                @include setProperty(margin-bottom, 10px);
                font-weight: 800;
            }

            .device-type {
                @include setProperty(margin-bottom, 30px);
            }

            .device-info-data {
                div {
                    @include setProperty(margin-bottom, 20px);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    app-settings-messaging {
        .settings-panel-messaging {
            .settings-panel-wrapper {
                @include setRows(height, 45);

                .settings-panel {
                    @include setRows(max-height, 45);
                }
            }

            .message-header {
                height: 100%;
                position: relative;

                .message-header-figure {
                    background-image: url('~assets/notifications/message.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    @include setProperty(width, 180px);
                    @include setRows(height, 8);
                    position: absolute;
                    left: 0;
                    top: 0;

                    &.message-icon-message {
                        background-image: url('~assets/notifications/message.png');
                    }

                    &.message-icon-alert {
                        background-image: url('~assets/notifications/alert.png');
                    }

                    &.message-icon-news {
                        background-image: url('~assets/notifications/news.png');
                    }
                }

                .message-header-content {
                    position: absolute;
                    @include setProperty(left, 250px);
                    @include setProperty(top, 30px);
                    @include setColumns(width, 10);
                    @include setProperty(font-size, 42px);
                    @include setProperty(line-height, 50px);
                    font-weight: 600;

                    .message-header-title {
                        @include h3();
                    }
                }

                .icon-done-circle-small {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            .settings-panel-item {
                @include setRows(height, 8, $important: true)
            }
        }

        .message {
            position: absolute;
            @include setProperty(right, 360px);
            @include setColumns(width, 15);
            @include setRows(top, 16);
            background: rgba($some-gray, 0.2);
            @include setProperty(border-radius, 10px);
            @include setProperty(padding-top padding-bottom padding-left padding-right, 50px);
            overflow: hidden;
            @include setProperty(font-size, 40px);

            .message-title {
                @include h3();
            }

            .message-text {
                @include setRows(margin-top, 1);
                @include setRows(height, 30);
                @include p();
            }

            app-buttons-panel {
                display: block;
                @include setRows(margin-top, 2);
            }
        }
    }

    .device-figure {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        @include setProperty(width, 180px);
        @include setProperty(height, 180px);
        position: absolute;
        left: 0;
        top: 0;
    }

    .device-figure-webbrowser {
        background-image: url('~assets/devices/laptop.png');

    }

    .device-figure-mobile {
        background-image: url('~assets/devices/mobile.png');
    }

    .device-figure-fix {
        background-image: url('~assets/devices/smarttv.png');
    }

    .device-figure-smarttv {
        background-image: url('~assets/devices/smarttv.png');
    }
}

@include theme('senior') {
    app-settings {

        .settings-panel-wrapper {
            &.settings-panel-wrapper-big {
                @include setColumns(width, 25);

                .settings-panel {
                    .settings-panel-items {
                        .settings-panel-item {
                            .settings-key {
                                @include setColumns(width, 19);
                            }
                        }
                    }
                }

                &.settings-panel-wrapper-inactive {
                    @include setColumns(width, 14);

                    .settings-panel {
                        .settings-panel-items {
                            .settings-panel-item {
                                .settings-key {
                                    @include setColumns(width, 12);
                                }
                            }
                        }
                    }
                }
            }
        }

        .settings-panel {
            @include setRows(max-height, 43);

            app-form {
                @include setRows(padding-top, 5);
            }

            .settings-panel-title {
                @include setProperty(font-size line-height, 100px);
            }

            .settings-panel-items {
                .settings-panel-item {
                    @include setRows(height, 6);
                    @include setProperty(border-radius, 20px);

                    .settings-key {
                        @include setProperty(font-size, 72px);
                        @include setRows(line-height, 6);
                    }

                    .settings-icon {
                        @include setRows(line-height, 6);
                    }

                    .settings-text {
                        @include setProperty(font-size, 56px);
                        @include setRows(line-height, 6);
                        @include setRows(height, 6);

                        .icon-done-circle-small {
                            @include setProperty(width, 80px);
                            @include setProperty(height, 80px);
                        }
                    }
                }
            }
        }

        .settings-panel-hint {
            @include setRows(bottom, -11);
        }

        .settings-audio-sub {
            .audio-title, .subtitle-title {
                @include setProperty(font-size, 90px);
                @include setProperty(line-height, 100px);

                &.audio-title {
                    @include setRows(top, 15);
                }

                &.subtitle-title {
                    @include setRows(top, 15);
                }
            }

            .settings-panel-audio {
                .settings-panel-wrapper {
                    @include setRows(top, 20);
                }
            }

            .settings-panel-subtitle {
                .settings-panel-wrapper {
                    @include setRows(top, 20);
                }
            }

            .settings-panel-audio-channel {
                .settings-panel-wrapper {
                    @include setRows(top, 42);
                }
            }

            .settings-panel-subtitle-channel {
                .settings-panel-wrapper {
                    @include setRows(top, 42);
                }
            }
        }
    }

    app-settings-device-management {
        .settings-panel-devices {
            .settings-panel-wrapper {
                @include setRows(height, 49);

                .settings-panel {
                    @include setRows(max-height, 49);
                }
            }

            .device {
                .device-content {
                    .device-title {
                        @include setProperty(font-size, 72px);
                        @include setProperty(line-height, 100px);
                    }

                    .device-sn {
                        @include setProperty(font-size, 56px);
                        @include setProperty(line-height, 60px);
                    }
                }

                .icon {
                    @include setProperty(top, 80px);
                    @include setProperty(width, 80px);
                    @include setProperty(height, 80px);
                }
            }

            .settings-panel-item {
                @include setRows(height, 11, $important: true)
            }

        }

        .settings-box-active-profile {
            @include setRows(top, 12);
        }
    }

    app-settings-device-info {
        .settings-device-info-content {
            @include setProperty(line-height, 72px);
            @include setProperty(font-size, 56px);

            .device-title {
                @include setProperty(font-size, 72px);
                @include setProperty(line-height, 80px);
            }
        }
    }
}
