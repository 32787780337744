app-filter, app-filter-new {
    display: block;
    position: relative;
    z-index: 1;

    .buttons-row {
        @include setRows(margin-bottom, 1);
        white-space: nowrap;

        .buttons-row-label {
            display: inline-block;
            vertical-align: middle;
            @include setColumns(width, 4);
            @include setColumns(margin-right, 1);
            @include setProperty(line-height, 120px);
            @include setProperty(font-size, 56px);
            font-weight: 800;
        }

        app-buttons-panel {
            display: inline-block;
            vertical-align: middle;
            @include setColumns(margin-left, 1);
            position: relative;
            @include setColumnsWithGutter(width, 35);

            .buttons-move {
                transition: transform $transition-duration $transition-timing-function;
            }
        }
    }
}
