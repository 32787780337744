app-screen-saver {
    .screen-saver-slide {
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-color: $backround-main;
        .screen-saver-title {
            @include h1();
            text-transform: uppercase;
            position: absolute;
            @include setRows(top, 47);
            @include setColumnsWithOffset(left, 3);
        }
        app-rating {
            position: absolute;
            @include setRows(top, 54);
            @include setColumnsWithOffset(left, 3);
        }
        .screen-saver-description {
            @include p();
            position: absolute;
            @include setRows(top, 58);
            @include setColumnsWithOffset(left, 3);
            @include setColumns(width, 20);
            @include setRows(height, 10);
        }
        .screen-saver-button {
            position: absolute;
            @include setRows(top, 40);
            @include setColumnsWithOffset(left, 3);
            @include setGutter(margin-right);
            @include setRows(height, 6);
            @include setProperty(padding-left padding-right, 50px);
            @include setProperty(border-radius, 10px);
            background-color: rgba($some-gray, 0.5);
            transform: translateZ(0);
            .icon-play-button-big {
                @include setProperty(margin-right, 32px);
                vertical-align: middle;
            }
            .button-label {
                text-align: center;
                @include setRows(line-height, 6);
                @include setProperty(font-size, 72px);
                font-weight: 800;
                text-transform: uppercase;
                color: $kuki-white;
                white-space: nowrap;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}
