/* You can add global styles to this file, and also import other style files */
$platform: TV-ARRIS;
$res: 720;

@import 'tv/variables/variables';
@import 'tv/platforms/arris/variables.arris';
@import 'global/fonts/font_ttf';
@import 'global/general/functions';
@import 'global/general/mixins';
@import 'global/general/placeholders';
@import 'tv/themes/theme';
@import 'tv/general/index';
@import 'global/general/helpers';
@import 'global/elements/index';
@import 'tv/elements/index';
@import 'global/icons/icons-png-720';
@import 'tv/platforms/arris/arris';

