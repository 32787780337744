.image-link-factory {
    // has to be split
    //background-size: cover;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    //background-position: center center;
}
