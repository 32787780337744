app-rating {
    display: block;
    overflow: hidden;
    .icon {
        float: left;
        @include setProperty(margin-right, 21px);
        @include setProperty(top, 4px);
    }
    .rating-text {
        float: left;
        color: $kuki-pink;
        @include setProperty(font-size, 60px);
        @include setProperty(margin-left, 36px);
        font-weight: 900;
    }
}

@include theme('senior') {
    app-rating {
        .icon {
            @include setProperty(width, 95px);
            @include setProperty(height, 90px);
        }

        .rating-text {
            @include setProperty(font-size, 90px);
        }
    }
}

@include theme('kid') {
    app-rating {
        .icon {
            @include setProperty(width, 95px);
            @include setProperty(height, 90px);
        }

        .rating-text {
            @include setProperty(font-size, 90px);
        }
    }
}
