h1 {
    @include setProperty(font-size, 150px);
    @include setProperty(line-height, 180px);
    font-weight: 900;
    margin: 0;
    padding: 0;
    vertical-align: text-top;
    @include setRows(top, -0.5);
    //margin-top: 0;
}

h2 {
    font-weight: 900;
    @include setProperty(font-size, 90px);
    @include setProperty(line-height, 90px);
    margin: 0;
    @include setRows(margin-bottom, 2);
}

h3 {
    @include setProperty(font-size, 60px);
    @include setProperty(line-height, 90px);
    font-weight: 900;
    margin: 0;
}


p {
    @include setProperty(font-size, 56px);
    @include setProperty(line-height, 60px);
    font-weight: 800;
    margin: 0;
    padding: 0;
    @include setRows(margin-bottom, 2);
}

@mixin h1 {
    @include setProperty(font-size, 150px);
    @include setProperty(line-height, 180px);
    font-weight: 900;
    vertical-align: text-top;
    @include setRows(top, -0.5);
}

@mixin h2 {
    @include setProperty(font-size, 90px);
    @include setProperty(line-height, 90px);
    font-weight: 900;
}

@mixin h3 {
    @include setProperty(font-size, 60px);
    @include setProperty(line-height, 90px);
    font-weight: 900;
}

@mixin p {
    @include setProperty(font-size, 56px);
    @include setProperty(line-height, 60px);
    font-weight: 800;
}
