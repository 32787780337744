.p-0 {
    padding: 0 !important;;
}

.mr-auto {
    margin-right: auto !important;
}

.d-flex {
    display: flex;
}

.ml-auto {
    margin-left: auto !important;
}


@for $i from 1 through 10 {
    .mb-#{$i} {
        @include setRows(margin-bottom, $i, true);
    }
    .mt-#{$i} {
        @include setRows(margin-top, $i, true);
    }
    .pl-#{$i} {
        @include setColumnsWithGutter(padding-left, $i, true);
    }
    .pb-#{$i} {
        @include setRows(padding-bottom, $i, true);
    }
    .b-#{$i} {
        @include setRows(bottom, $i, true);
    }
    .t-#{$i} {
        @include setRows(top, $i, true);
    }
}
