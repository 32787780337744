.volume-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include setProperty(width, 500px);
    @include setProperty(height, 380px);
    @include setProperty(border-radius, 50px);
    background: rgba($kuki-dark, 0.9);
    margin: auto;
    @include setProperty(padding-left padding-right, 50px);

    @include arris {
        @include res-1080 {
            @include setProperty(height, 360px);
        }
    }

    .volume-panel {
        position: relative;

        .volume-icon {
            position: absolute;
            @include setProperty(top, 50px);
            left: 0;
            right: 0;
            margin: auto;
        }

        .volume-bar {
            position: absolute;
            @include setProperty(top, 270px);
            background: $kuki-pink;
            height: 15px;
        }

        .volume-text {
            position: absolute;
            @include setProperty(top, 350px);
            color: $kuki-pink;
            @include setProperty(font-size, 90px);
            font-weight: 900;
            text-align: center;
            left: 0;
            right: 0;
        }

        .volume-unvalaible-text {
            position: absolute;
            @include setProperty(top, 40px);
            color: $kuki-pink;
            @include setProperty(font-size, 90px);
            font-weight: 900;
            text-align: center;
            left: 0;
            right: 0;
        }

        .volume-mute {
            position: absolute;
            @include setProperty(top, 17px);
            color: $kuki-pink;
            @include setProperty(font-size, 180px);
            font-weight: 900;
            text-align: center;
            left: 0;
            right: 0;
        }
    }
}
