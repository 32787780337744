app-buttons-panel, .buttons-panel {
    white-space: nowrap;
    display: block;
    position: relative;
    overflow: hidden;
    @include setRows(height, 6);
    @include setRows(padding-top, 1);
    @include setRows(margin-top, -1);
    @include setRows(margin-bottom, -1);
    @include setColumns(padding-left, 1);
    @include setColumns(margin-left, -1);

    &.buttons-panel-vertical {
        white-space: normal;
        height: auto !important;

        .button {
            display: block;
            margin: auto;
            @include setColumns(width, 8);
            @include setRows(margin-bottom, 1);

            &:after {
                content: '\A';
                white-space: pre;
            }

            &:last-child {
                margin-right: auto;
            }
        }
    }

    .button {
        display: inline-block;
        vertical-align: middle;
        @include setGutter(margin-right);
        @include setRows(height, 4);
        @include setProperty(padding-left padding-right, 33px);
        @include setProperty(border-radius, 10px);
        background-color: rgba($some-gray, 0.2);
        transform: translateZ(0);
        @include not-arris {
            transition: transform $transition-duration $transition-timing-function;
        }

        &:last-child {
            margin-right: 0;
        }

        &.button-with-icon {
            .icon {
                position: relative;
                vertical-align: middle;
                @include setProperty(margin-right, 32px);
            }

            .button-label {
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                @include setProperty(max-width, 500px);
            }
        }

        .button-label {
            text-align: center;
            @include setRows(line-height, 4);
            @include setProperty(font-size, 56px);
            font-weight: 800;
            text-transform: uppercase;
            color: $kuki-white;
            white-space: nowrap;
        }

        &.button-active {
            background-color: rgba($some-gray, 0.5);
            @include not-arris {
                transform: scale3d(1.1, 1.1, 1);
            }
        }

        &.button-selected {
            background-color: $kuki-pink;

            &.button-active {
                background: $kuki-pink-2 !important;
            }
        }

        &.button-fade-out {
            opacity: 0.2;
        }

        &.btn-record {
            .icon {
                @include setProperty(width, 60px);
                @include setProperty(height, 64px);
            }
        }

        &.button-cant-buy {
            background: $kuki-pink !important;
            transform: none !important;
        }
    }
}

@include theme('senior') {
    app-buttons-panel, .buttons-panel {
        @include setRows(height, 10);
        @include setRows(padding-top, 2);
        @include setRows(margin-top, -2);
        @include setRows(margin-bottom, -2);

        &.buttons-panel-vertical {
            .button {
                @include setColumns(width, 12);
            }
        }

        .button {
            @include setProperty(padding-left padding-right, 50px);
            @include setRows(height, 6);
            background-color: $kuki-senior-secondary;
            position: relative;
            //@include setProperty(border, 4px, solid $kuki-light);

            &:before {
                content: "";
                position: absolute;
                z-index: 1;
                @include setProperty(left right top bottom, -2px);
                @include setProperty(border, 4px, solid $kuki-light);
                @include setProperty(border-radius, 10px);
            }

            .button-label {
                @include setRows(line-height, 6);
                @include setProperty(font-size, 72px);
            }

            &.button-with-icon {
                .button-label {
                    @include setProperty(max-width, 800px);
                }
            }

            &.button-active {
                &:before {
                    @include setProperty(border, 6px, solid $kuki-white);
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                }
            }

            &.button-selected {
                background-color: $kuki-pink;

                &.button-active {
                    background: $kuki-pink-2 !important;
                }
            }
        }
    }
}

@include theme('kid') {
    app-buttons-panel, .buttons-panel {
        @include setRows(height, 11);
        @include setRows(padding-top, 2);
        @include setRows(margin-top, -2);
        @include setRows(margin-bottom, -2);

        .button {
            @include setProperty(border-radius, 20px);
            @include setProperty(padding-left padding-right, 60px);
            @include setRows(height, 7);

            .button-label {
                @include setRows(line-height, 7);
                @include setProperty(font-size, 72px);
            }
        }
    }
}
