
.mouse-control {
    position: absolute;
    z-index: 999;
    transition: transform $transition-duration $transition-timing-function;
    cursor: pointer;
    user-select: none;
    @include setProperty(width, 140px);
    @include setProperty(height, 140px);
    background: rgba($kuki-purple, 0.8);
    //border: solid $kuki-pink-2;
    //@include setProperty(border-width, 5px);
    border-radius: 50%;
    transform: translateZ(0);
    margin: auto;

    &:hover {
        transform: scale3d(1.1, 1.1, 1);
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        content: "";
        @include setProperty(width, 90px);
        @include setProperty(height, 100px);
        background-size: 100% 100%;
    }

    &.mouse-control-up {
        @include setProperty(top, 20px);
        left: 0;
        right: 0;

        &:before {
            background-image: url('~assets/tv-nav/arrow-up-tv-nav.svg');
        }
    }

    &.mouse-control-down {
        @include setProperty(bottom, 20px);
        left: 0;
        right: 0;

        &:before {
            background-image: url('~assets/tv-nav/arrow-down-tv-nav.svg');
        }
    }

    &.mouse-control-left {
        @include setProperty(left, 20px);
        top: 0;
        bottom: 0;

        &:before {
            background-image: url('~assets/tv-nav/arrow-left-tv-nav.svg');
        }
    }

    &.mouse-control-right {
        @include setProperty(right, 20px);
        top: 0;
        bottom: 0;

        &:before {
            background-image: url('~assets/tv-nav/arrow-right-tv-nav.svg');
        }
    }

    &.mouse-control-ok {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

}
